<template>
  <div id="app" :class="{ skinClasses, 'h-100': isClassH100 }">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// Add the Firebase products that you want to use
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import appUtils from "@/utils/appUtils";
import { getMessaging, onMessage } from "firebase/messaging";

import { useWindowSize, useCssVar } from "@vueuse/core";
import { initializeApp } from "firebase/app";
import { mapState } from "vuex";

import store from "@/store";
import { NOTIFICATION_TYPE, ROLES_SYSTEM } from "./utils/constant";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    isClassH100() {
      return !["print-blood-sample-form", "print-require-form"].includes(
        this.$route.name
      );
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    ...mapState({
      authenticate: (state) => state.authenticate,
    }),
  },
  watch: {
    authenticate: {
      deep: true,
      handler() {
        this.handleCheckRole();
        this.getOrgConfigService();
      },
    },
  },
  destroyed() {
    if (window.client) {
      window.client.disconnect();
    }
  },
  async created() {
    await this.getOrgConfigService();
    this.handleCheckRole();
    if (appUtils.getLocalUser()) {
      this.$user = appUtils.getLocalUser();
    }
  },
  mounted() {
    var firebaseConfig = {
      apiKey: "AIzaSyDvMMgJWMS8vgtfslZJ56byo3akYbfupZM",
      authDomain: "hodo-46abc.firebaseapp.com",
      databaseURL: "https://hodo-46abc.firebaseio.com",
      projectId: "hodo-46abc",
      storageBucket: "hodo-46abc.appspot.com",
      messagingSenderId: "923239105015",
      appId: "1:923239105015:web:c8958b39259abffa15eef2",
      measurementId: "G-Y47WD5M5E1",
    };

    initializeApp(firebaseConfig);
    this.receiveFirebase();
  },
  methods: {
    receiveFirebase() {
      onMessage(getMessaging(), (payload) => {
        console.log("Message received. ", payload);
        const notification = payload?.notification?.body;
        if (notification) {
          const parseData = JSON.parse(notification);
          const totalNotificationUnread = parseData?.count_unread || 0;
          this.$store.commit("notification/setHasNewMessage", {
            isNew: true,
            total: totalNotificationUnread,
          });
        }

        const userData = appUtils.getLocalUser();
        const isProcessor = userData?.processors?.length;
        if (!userData?.id) return;
        const params = {
          user_id: userData.id,
          type_notification: isProcessor
            ? NOTIFICATION_TYPE.PROCESSOR
            : NOTIFICATION_TYPE.SUPPLIER,
          sort_by: "created_at",
          order: "desc",
          page_num: 1,
          page_size: 10,
        };

        this.$store.dispatch("notification/getNotifications", {
          params,
          isPush: false,
        });
      });
    },
    handleCheckRole() {
      const userData = this.authenticate?.userLogin || {};
      const isSupplierProduct = false;
      const isSupplier = userData?.suppliers?.length;
      const isProcessor = userData?.processors?.length;
      // Supplier Product
      if (isSupplierProduct) {
        this.$role = ROLES_SYSTEM.SUPPLIER_PRODUCT;
        return;
      }
      if (isProcessor) {
        this.$role = ROLES_SYSTEM.PROCESSOR;
      } else if (isSupplier) {
        this.$role = ROLES_SYSTEM.SUPPLIER;
      } else {
        this.$role = ROLES_SYSTEM.ALL;
      }
    },
    async getOrgConfigService() {
      try {
        const userData = appUtils.getLocalUser();

        const orgId = userData?.supplierInfo?.org_id;
        if (!orgId) {
          this.$partnerFeature = {};
          return;
        }
        const response = await this.$rf
          .getRequest("CommonRequest")
          .getOrgFeature(orgId);

        store.commit("app/setPartnerFeature", response.data || {});
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
};
</script>
