import Vue from "vue"
import Vuex from "vuex"
// Modules
import app from "./app"
import appConfig from "./app-config"
import authenticate from "./authenticate"
import categories from "./categories"
import orders from "./orders"
import ordersProcessor from "./ordersProcessor"
import inventory from "./inventory"
import phamarProduct from "./phamarProduct"
import products from "./products"
import verticalMenu from "./vertical-menu"
import reports from "./reports"
import campaigns from "./campaigns"
import notification from "./notification"
import language from "./language"
import receiving from "./receiving"
import ecomInventory from "./ecom/ecomInventory"
import stockIn from "./ecom/stockIn"
import stockOut from "./ecom/stockOut"
import ecomProvider from "./ecom/ecomProvider"
import ecomProduct from "./ecom/ecomProduct"
import ecomLot from "./ecom/lot"
import ecomOrder from "./ecom/order"
import configProduct from "./configProduct"
import configProductPartner from "./configProductPartner"
import configCampaign from "./configCampaigns"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authenticate,
    phamarProduct,
    ecomInventory,
    orders,
    products,
    categories,
    inventory,
    ordersProcessor,
    reports,
    receiving,
    stockIn,
    stockOut,
    ecomLot,
    ecomProvider,
    ecomProduct,
    ecomOrder,
    notification,
    campaigns,
    language,
    configProduct,
    configProductPartner,
    configCampaign,
  },
  strict: process.env.DEV,
})
