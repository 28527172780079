
import i18n from "@/libs/i18n"
import auth from "@/middleware/auth"
import { PRODUCT_TYPES } from "@/utils/constant"

export const testsRoutes = [
  {
    path: "/services",
    name: "services",
    component: () => import("@/views/Products/index.vue"),
    meta: {
      can: 'service-management',
      type: PRODUCT_TYPES.SERVICE,
      pageTitle: i18n.t("nav_menu.lbl_test_list"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/service/add",
    name: "add-service",
    component: () => import("@/views/Products/Add/index.vue"),
    meta: {
      can: 'service-management',
      pageTitle: "lbl_add_product",
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_product___services",
          active: false,
          to: "/services",
        },
        {
          text: "lbl_add_new",
          active: true,
        },
      ],
    },
  },
  {
    path: "/services/:slug",
    name: "update-service",
    component: () => import("@/views/Products/Add/index.vue"),
    meta: {
      can: 'service-management',
      pageTitle: "lbl_update",
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_product___services"),
          active: false,
          to: "/services",
        },
        {
          text: "lbl_update_product_&_service",
          active: true,
        },
      ],
    },
  },
  // Inventory
  {
    path: "/inventory-service",
    name: "inventory-service",
    component: () => import("@/views/Inventory/index.vue"),
    meta: {
      can: 'service-management',
      type: PRODUCT_TYPES.SERVICE,
      pageTitle: i18n.t("lbl_inventory"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  // Orders
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/Orders/index.vue"),
    meta: {
      can: 'service-orders',
      pageTitle: i18n.t("lbl_orders"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/order/:id",
    name: "orderDetail",
    component: () => import("@/views/Orders/Detail/orderDetail.vue"),
    meta: {
      can: 'service-orders',
      pageTitle: i18n.t("lbl_orders_detail"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_orders"),
          active: false,
          to: "/orders",
        },
        {
          text: "lbl_detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: () => import("@/views/Campaigns/index.vue"),
    meta: {
      can: 'campaigns',
      pageTitle: i18n.t("lbl_campaigns"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  // Orders Processor
  {
    path: "/orders-processor",
    name: "orders-processor",
    component: () => import("@/views/OrdersProcessor/index.vue"),
    meta: {
      can: 'processor-service-orders',
      pageTitle: "lbl_required_test_manager",
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders-processor/:id",
    name: "detail-order-processor",
    component: () => import("@/views/OrdersProcessor/Detail/orderDetail.vue"),
    meta: {
      can: 'processor-service-orders',
      pageTitle: "lbl_orders_detail",
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_required_test_manager",
          active: false,
          to: "/orders-processor",
        },
        {
          text: "lbl_detail",
          active: true,
        },
      ],
    },
  },
  // Configs
  {
    path: "/config-definition",
    name: "config-definition",
    component: () => import("@/views/Configs/Definition/index.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_delivery_definition_configs"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-delivery-status",
    name: "config-delivery-status",
    component: () => import("@/views/Configs/DeliveryStatus/index.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_delivery_status_configs"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-order-form",
    name: "config-order-form",
    component: () => import("@/views/Configs/OrderForm/index.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_required_attachment_configs"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/print-order-form/:id",
    name: "print-order-form",
    component: () => import("@/views/PrintOrderForm/index.vue"),
    meta: {
      can: 'service-management',
      middleware: [auth],
      layout: "full",
    },
  },
  {
    path: "/print-indicator-form/:id",
    name: "PrintIndicatorForm",
    component: () => import("@/views/PrintOrderForm/PrintIndicatorForm.vue"),
    meta: {
      can: 'service-management',
      middleware: [auth,],
      layout: "full",
    },
  },
]
