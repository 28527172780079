<template>
  <div>
    <span class="title-form">PHIẾU YÊU CẦU XÉT NGHIỆM</span>
    <div class="mt-3">
      <div id="printOrderForm" class="mt-3">
        <div>
          <div>
            <!-- PATIENT INFORMATION -->
            <div>
              <div class="cs-flex">
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Full name/ <i>Họ tên</i></span>
                  <b-form-input class="cs-content" v-model="form.full_name" />
                </div>
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Age/ <i>Tuổi</i></span>
                  <b-form-input v-model="form.age" class></b-form-input>
                </div>
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Gender/ <i>Giới</i></span>
                  <div class="d-flex cs-content">
                    <div class="mr-1 cs-checkbox" :set="isMale=form.gender===1">
                      <input
                        v-model="isMale"
                        id="male"
                        name="male"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.gender = 1 : form.gender = null"
                      />
                      <label>Male/ <i>Nam</i></label>
                    </div>
                    <div class="mr-1 cs-checkbox" :set="isFemale=form.gender===2">
                      <input
                        v-model="isFemale"
                        id="female"
                        name="female"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.gender = 2 : form.gender = null"
                      />
                      <label>Female/ <i>Nữ</i></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cs-flex">
                <div class="col-12 col-sm-7">
                  <span class="cs-label">Address/ <i>Địa chỉ</i></span>
                  <b-form-input class="cs-content" v-model="form.address" />
                </div>
                <div class="col-12 col-sm-5">
                  <span class="cs-label">Phone Number/ <i>Điện thoại</i></span>
                  <b-form-input class="cs-content" v-model="form.phone_number" />
                </div>
              </div>
              <div class="cs-flex">
                <div class="col-12">
                  <span class="cs-label">Dianose/ <i>Chẩn đoán</i></span>
                  <quillEditor v-model="form.diagnostic_results" :options="optionsEditor" />
                </div>
              </div>
            </div>
            <!-- PATIENT INFORMATION -->
            
            <!-- TEST INFORMATION TABLE -->
            <div class="mb-2">
              <div>
                <b-row class="mt-1 mb-1 mx-0">
                  <b-col class="text-center" cols="6">
                    <strong>Test Name/ <i>Tên xét nghiệm</i></strong>
                  </b-col>
                  <b-col class="text-center" cols="6">
                    <strong>Test Sample/ <i>Mẫu bệnh phẩm</i></strong>
                  </b-col>
                </b-row>
                <b-row class="mt-1 px-1">
                  <b-col cols="6">
                    <b-form-input v-model="form.test_selection.product_name" />
                  </b-col>
                  <b-col class="text-center" cols="6">
                    <b-form-input v-model="form.test_selection.test_sample" />
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- TEST INFORMATION TABLE -->
          </div>
        </div>

        <hr />

        <div>
          <div>
            <!-- PATIENT INFORMATION -->
            <div>
              <div class="cs-flex">
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Full name/ <i>Họ tên</i></span>
                  <b-form-input class="cs-content" v-model="form.other_person.full_name" />
                </div>
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Age/ <i>Tuổi</i></span>
                  <b-form-input v-model="form.other_person.age" class></b-form-input>
                </div>
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Gender/ <i>Giới</i></span>
                  <div class="d-flex cs-content">
                    <div class="mr-1 cs-checkbox" :set="isMale=form.other_person.gender===1">
                      <input
                        v-model="isMale"
                        id="male"
                        name="male"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.other_person.gender = 1 : form.other_person.gender = null"
                      />
                      <label>Male/ <i>Nam</i></label>
                    </div>
                    <div class="mr-1 cs-checkbox" :set="isFemale=form.other_person.gender===2">
                      <input
                        v-model="isFemale"
                        id="female"
                        name="female"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.other_person.gender = 2 : form.other_person.gender = null"
                      />
                      <label>Female/ <i>Nữ</i></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cs-flex">
                <div class="col-12 col-sm-7">
                  <span class="cs-label">Address/ <i>Địa chỉ</i></span>
                  <b-form-input class="cs-content" v-model="form.other_person.address" />
                </div>
                <div class="col-12 col-sm-5">
                  <span class="cs-label">Phone Number/ <i>Điện thoại</i></span>
                  <b-form-input class="cs-content" v-model="form.other_person.phone_number" />
                </div>
              </div>
              <div class="cs-flex">
                <div class="col-12">
                  <span class="cs-label">Dianose/ <i>Chẩn đoán</i></span>
                  <quillEditor v-model="form.other_person.diagnostic_results" :options="optionsEditor" />
                </div>
              </div>
            </div>
            <!-- PATIENT INFORMATION -->
            
            <!-- TEST INFORMATION TABLE -->
            <div class="mb-2">
              <div>
                <b-row class="mt-1 mb-1 mx-0">
                  <b-col class="text-center" cols="6">
                    <strong>Test Name/ <i>Tên xét nghiệm</i></strong>
                  </b-col>
                  <b-col class="text-center" cols="6">
                    <strong>Test Sample/ <i>Mẫu bệnh phẩm</i></strong>
                  </b-col>
                </b-row>
                <b-row class="mt-1 px-1" v-if="form.other_person.test_selection && form.other_person.test_selection.product_name">
                  <b-col cols="6">
                    <b-form-input v-model="form.other_person.test_selection.product_name" />
                  </b-col>
                  <b-col class="text-center" cols="6">
                    <b-form-input v-model="form.other_person.test_selection.test_sample" />
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- TEST INFORMATION TABLE -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { mapState } from "vuex";

const DEFAULT_FORM = {
  full_name: "",
  age: "",
  gender: null,
  address: "",
  phone_number: "",
  doctor_name: "",
  clinic: "",
  doctor_phone_number: null,
  sample_taken_at: "",
  sample_id: "",
  test_selection: {
    product_name: "",
    test_sample: ""
  },
  other_person: {
    full_name: "",
    age: "",
    gender: null,
    address: "",
    phone_number: "",
    diagnostic_results: "",
    test_selection: {
      product_name: "",
      test_sample: ""
    }
  }
};

const optionsEditor = {
  placeholder: "",
  theme: "snow"
};

export default {
  name: "CreateRequireForm",
  props: {
    productData: Object,
    createRequisitionFormCount: Number,
    formCount: Number,
    formData: Object
  },
  components: { quillEditor },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      optionsEditor
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    createRequisitionFormCount() {
      this.handleCreateRequisitionForm();
    },
    formCount(count) {
      if (!count) return;
      this.handleGetFormData();
    },
    orders: {
      deep: true,
      handler(data) {
        if (data?.viewDetail) {
          this.handleMapData(data?.viewDetail);
        }
      }
    }
  },
  created() {
    console.log(this.form)
    
    if (this.orders?.viewDetail) {
      console.log("sadadas")
      this.handleMapData(this.orders.viewDetail, this.formData);
    }
  },
  // mounted() {
  //   console.log(this.form)
  //   if (this.orders?.viewDetail) {
  //     console.log("sadadas")
  //     this.handleMapData(this.orders.viewDetail, this.formData);
  //   }
  // },
  methods: {
    handleChangeSolidTumourType(isChecked, index) {
      if (isChecked) {
        this.form.solid_tumour_type.push(index);
      } else {
        this.form.solid_tumour_type = this.form.solid_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleChangeHaematologicalTumourType(isChecked, index) {
      if (isChecked) {
        this.form.haematological_tumour_type.push(index);
      } else {
        this.form.haematological_tumour_type = this.form.haematological_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleCreateRequisitionForm() {
      if (this.createRequisitionFormCount) {
        this.$emit("onCreate", this.form);
      } else {
        this.form = { ...DEFAULT_FORM };
      }
    },
    handleMapData(data, formData) {
      if (formData) {
        this.form = { ...this.form, ...formData };
      }
      const patientInfo =
        data?.order_contact?.find(item => item?.type === 2) ||
        data?.order_contact?.find(item => item?.type === 1);
      this.form.full_name = formData?.full_name || patientInfo?.name;
      this.form.address = formData?.address ||
        patientInfo?.address1 ||
        patientInfo?.address2;
      this.form.phone_number = formData?.phone_number || patientInfo?.phone;
      this.form.email = formData?.email || patientInfo?.email;
    },
    handleGetFormData() {
      this.$emit("onChangeFormData", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.title-form {
  font-size: 24px;
  color: black;
  text-align: center;
}
label {
  font-size: 14px;
}
.cs-container {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-label {
  width: 150px;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.cs-none {
  pointer-events: none;
}
.w-30 {
  width: 30%;
}
.flex-grow-2 {
  flex-grow: 2;
}
@media (max-width: 576px) {
  .w-30 {
    width: 100%;
  }
  .d-flex {
    flex-wrap: wrap;
  }
  .cs-label {
    width: 100px;
  }
  .cs-flex {
    gap: 8px;
  }
}
.cs-flex {
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}
@media (max-width: 576px) {
  .cs-flex {
    column-gap: 8px;
  }
}
</style>
<style>
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
</style>