<template>
  <div>
    <span class="title-form">PHIẾU ĐỒNG THUẬN XÉT NGHIỆM SÀNG LỌC TIỀN SINH KHÔNG XÂM LẤN - NIPT</span>
    <div class="mt-3">
      <div id="printOrderForm" class="mt-3">
        <div>
          <div>
            <!-- PATIENT INFORMATION -->
            <div>
              <span class="cs-title">PHẦN 1: THÔNG TIN CHUNG</span>
              <div class="cs-flex">
                <span class="cs-label">Họ tên (IN HOA):</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.full_name" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Ngày sinh:</span>
              </div>
              <div class="cs-flex">
                <b-form-input
                  placeholder="Chọn ngày sinh"
                  type="date"
                  class="cs-content"
                  v-model="form.date"
                />
              </div>
              <div class="cs-flex">
                <span class="cs-label">SĐT Thai phụ:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.phone_number" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Địa chỉ</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.patient_address" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Email:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.patient_email" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Bác Sĩ:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.doctor" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Phòng khám/BV:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.hospital" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Ngày lấy máu:</span>
              </div>
              <div class="cs-flex">
                <b-form-input
                  placeholder="Chọn ngày sinh"
                  type="date"
                  class="cs-content"
                  v-model="form.sample_collection_date_time"
                />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Nơi lấy máu:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.place" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Mã XN:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.factory_code" />
              </div>
            </div>
            <!-- PATIENT INFORMATION -->
            <!-- REFERRING PHYSICIAN INFORMATION -->
            <div>
              <div>
                <span class="cs-title">PHẦN 2. THÔNG TIN LÂM SÀNG</span>
                <div class="cs-flex-2 cs-border-b">
                  <span class="cs-label text-danger">Đơn thai*:</span>
                  <div class="d-flex cs-content">
                    <div class="mr-1 cs-checkbox" :set="isOne=form.twins===1">
                      <input
                        class="cs-content"
                        v-model="isOne"
                        id="is-one"
                        name="is-one"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.twins = 1 : form.twins = null"
                      />
                      <label>Có</label>
                    </div>
                    <div class="mr-1 cs-checkbox" :set="isTwo= form.twins===2">
                      <input
                        class="cs-content"
                        v-model="isTwo"
                        id="is-two"
                        name="is-two"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.twins = 2 : form.twins = null"
                      />
                      <label>Không</label>
                    </div>
                  </div>
                </div>
                <div class="cs-flex-2 cs-border-b">
                  <span class="cs-label text-danger">Song thai tiêu biến*:</span>
                  <div class="d-flex cs-flex cs-content">
                    <div class="ml-1 cs-checkbox" :set="isFirst=form.crazy_twins===1">
                      <input
                        v-model="isFirst"
                        id="first"
                        name="first"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.crazy_twins = 1 : form.crazy_twins = null"
                      />
                      <label>Có</label>
                    </div>
                    <div class="ml-1 cs-checkbox" :set="isSecond= form.crazy_twins===2">
                      <input
                        v-model="isSecond"
                        id="second"
                        name="second"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.crazy_twins = 2 : form.crazy_twins = null"
                      />
                      <label>Không</label>
                    </div>
                  </div>
                </div>
                <div class="cs-flex-2 cs-border-b">
                  <span class="cs-label text-danger">Thai IVF*:</span>
                  <div class="d-flex cs-content">
                    <div class="mr-1 cs-checkbox" :set="isYes=form.ivf_fetus===1">
                      <input
                        v-model="isYes"
                        id="hospital"
                        name="hospital"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.ivf_fetus = 1 : form.ivf_fetus = null"
                      />
                      <label>Có</label>
                    </div>
                    <div class="mr-1 cs-checkbox" :set="isNo= form.ivf_fetus===2">
                      <input
                        v-model="isNo"
                        id="hospital"
                        name="hospital"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.ivf_fetus = 2 : form.ivf_fetus = null"
                      />
                      <label>Không</label>
                    </div>
                  </div>
                </div>
                <div class="cs-flex">
                  <span class="cs-label">Tuổi thai (tuần tuổi):</span>
                </div>
                <div class="cs-flex">
                  <b-form-input class="cs-content" v-model="form.age" />
                </div>

                <div class="cs-flex">
                  <span
                    class="cs-label-3 label text-danger"
                  >Các mục đánh dâu * là thông tin bắt buộc</span>
                </div>
                <div class="cs-flex-3 cs-border-b">
                  <span>Xét nghiệm NIPT KHÔNG được khuyến cáo thực hiện trên song thai, song thai tiêu biến (bao gồm cả trường hợp IVF đặt nhiều phôi nhưng chỉ 01 phôi phát triển), NT > 3,5mm, nguy cơ > 1/50, thai có siêu âm bất thường</span>
                </div>
                <div>
                  <div class="cs-flex">
                    <span class="cs-label">Ngày siêu âm:</span>
                  </div>
                  <div class="cs-flex">
                    <b-form-input
                      placeholder="Chọn ngày sinh"
                      type="date"
                      class="cs-content"
                      v-model="form.x_ray_date"
                    />
                  </div>
                </div>
                <div>
                  <div class="cs-flex">
                    <span>Chiều dài cầu mông(CRL):</span>
                  </div>
                  <div class="cs-flex">
                    <b-form-input class="cs-content" v-model="form.general_information1"></b-form-input>
                  </div>
                </div>
                <div>
                  <div class="cs-flex">
                    <span class="cs-label">Độ mờ da gáy(NT):</span>
                  </div>
                  <div class="cs-flex">
                    <b-form-input class="cs-content" v-model="form.general_information2"></b-form-input>
                  </div>
                </div>
                <div>
                  <div class="cs-flex">
                    <span class="cs-label">Nguy cơ sàng lọc trước sinh:</span>
                  </div>
                  <div class="cs-flex">
                    <b-form-input class="cs-content" v-model="form.general_information3"></b-form-input>
                  </div>
                </div>
                <div>
                  <div class="cs-flex">
                    <span class="cs-label">Chiều cao thai phụ:</span>
                  </div>
                  <div class="cs-flex">
                    <b-form-input class="cs-content" v-model="form.general_information4" />
                  </div>
                </div>
                <div>
                  <div class="cs-flex">
                    <span class="cs-label">Cân nặng thai phụ:</span>
                  </div>
                  <div class="cs-flex">
                    <b-form-input class="cs-content" v-model="form.general_information5"></b-form-input>
                  </div>
                </div>
                <div class="cs-flex cs-content">
                  <span>
                    Sàng lọc 09 bệnh di truyền lặn đơn gen
                    <span class="text-danger"></span>*:
                  </span>
                </div>
                <div class="cs-flex cs-content">
                  <div class="mr-1 cs-checkbox" :set="isYes=form.tick===1">
                    <input
                      v-model="isYes"
                      id="hospital"
                      name="hospital"
                      type="checkbox"
                      @change="(e)=>e.target.checked ? form.tick = 1 : form.tick = null"
                    />
                    <label>Có</label>
                  </div>
                  <div class="mr-1 cs-checkbox" :set="isNo= form.tick===2">
                    <input
                      v-model="isNo"
                      id="self-pay"
                      name="self-pay"
                      type="checkbox"
                      @change="(e)=>e.target.checked ? form.tick = 2 : form.tick = null"
                    />
                    <label>Không</label>
                  </div>
                </div>
                <span class="experiment">
                  (Áp dụng khi đăng ký xét nghiệm
                  <strong>NIPT5</strong> và
                  <strong>NIPT24</strong>)
                </span>
              </div>
            </div>
            <!-- REFERRING PHYSICIAN INFORMATION -->
            <!-- ACCOUNT INFORMATION -->
            <div>
              <span class="cs-title">PHẦN 3. THỎA THUẬN SỬ DỤNG DỊCH VỤ</span>
              <div class="cs-flex">
                <span>
                  <strong>A. Tôi lựa chọn xét nghiệm (tích vào ô chọn)</strong>
                </span>
              </div>
            </div>
            <div>
              <div class="cs-flex-2">
                <div class="left-table">
                  <div class="d-flex cs-content">
                    <div class="mb-1 cs-checkbox" :set="isNipt24= form.nipt_24 ===1">
                      <input
                        v-model="isNipt24"
                        id="nipt-24"
                        name="nipt-24"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.nipt_24 = 1 : form.nipt_24 = null"
                      />
                      <label>Xét nghiệm NIPT24: khảo sát nguy cơ lệch bội của tất cả các nhiễm sắc thể - NST (22 NST thường và cặp NST giới tính, không khảo sát XYY) ở thai nhi</label>
                    </div>
                  </div>
                  <div class="d-flex cs-content">
                    <div class="mb-1 cs-checkbox" :set="isNipt5= form.nipt_5 ===2">
                      <input
                        v-model="isNipt5"
                        id="nipt-5"
                        name="nipt-5"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.nipt_5 = 2 : form.nipt_5 = null"
                      />
                      <label>Xét nghiệm NIPT5: khảo sát nguy cơ lệch bội của 5 NST 21, 13, 18 và cặp NST giới tính (trisomy 21, trisomy 18, trisomy 13, XO, XXX, XXY, không khảo sát XYY)</label>
                    </div>
                  </div>
                  <div class="d-flex cs-content">
                    <div class="mb-1 cs-checkbox" :set="isNipt4= form.nipt_4 === 3">
                      <input
                        v-model="isNipt4"
                        id="nipt-4"
                        name="nipt-4"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.nipt_4 = 3 : form.nipt_4 = null"
                      />
                      <label>Xét nghiệm NIPT4: khảo sát nguy cơ mắc 4 hội chứng tam NST 21 (trisomy 21), tam NST 18 (trisomy 18), tam NST 13 (trisomy 13), XO (monosomy X) ở thai nhi.</label>
                    </div>
                  </div>
                  <div class="d-flex cs-content">
                    <div class="mb-1 cs-checkbox" :set="isNipt3= form.nipt_3 === 4">
                      <input
                        v-model="isNipt3"
                        id="nipt-3"
                        name="nipt-3"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.nipt_3 = 4 : form.nipt_3 = null"
                      />
                      <label>Xét nghiệm NIPT3: khảo sát nguy cơ mắc 3 hội chứng Tam NST 21 (trisomy 21), 18 (trisomy 18), 13 (trisomy 13) ở thai nhi.</label>
                    </div>
                  </div>
                  <div class="d-flex cs-content">
                    <div class="mb-1 cs-checkbox" :set="isNipt= form.nipt === 5">
                      <input
                        v-model="isNipt"
                        id="nipt"
                        name="nipt"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.nipt = 5 : form.nipt = null"
                      />
                      <label>Xét nghiệm NIPT............</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Checkboxes are here -->
            </div>
            <div>
              <span class="cs-title">PHẦN 4. THỎA THUẬN SỬ DỤNG DỊCH VỤ</span>
              <div class="cs-flex">
                <span >Tôi đồng ý làm xét nghiệm NIPT:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.agreement" />
              </div>
              <div class="cs-flex cs-border-b">
                <span class="text-danger">Kèm xét nghiệm 9 gen bệnh ẩn:</span>
              </div>
              <div class="cs-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isYay=form.twins_checkbox===1">
                  <input
                    v-model="isYay"
                    id="yes"
                    name="yes"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.twins_checkbox = 1 : form.twins_checkbox = null"
                  />
                  <label>Có</label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isNah= form.twins_checkbox===2">
                  <input
                    v-model="isNah"
                    id="no"
                    name="no"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.twins_checkbox = 2 : form.twins_checkbox = null"
                  />
                  <label>Không</label>
                </div>
              </div>
              <div class="cs-flex">
                <span>Ngày(Cho bệnh nhân ký)</span>
              </div>
              <div class="cs-flex">
                <b-form-input
                  placeholder="Chọn ngày sinh"
                  type="date"
                  class="cs-content"
                  v-model="form.signing_day_patient"
                />
              </div>
              <div class="cs-flex">
                <span>Ngày(Cho bác sĩ ký)</span>
              </div>
              <div class="cs-flex">
                <b-form-input
                  placeholder="Chọn ngày sinh"
                  type="date"
                  class="cs-content"
                  v-model="form.signing_day_doctor"
                />
              </div>
            </div>
          </div>

          <!-- COLUMN RIGHT -->
        </div>
        <!-- PAGE 2 -->
      </div>
    </div>
  </div>
</template>
    
  <script>
import { quillEditor } from "vue-quill-editor";
import { mapState } from "vuex";

const DEFAULT_FORM = {
  tick: null,
  general_information1: "",
  general_information2: "",
  general_information3: "",
  general_information4: "",
  general_information5: "",
  twins: null,
  age: "",
  week: "",
  full_name: "",
  phone_number: "",
  hospital: "",
  doctor: "",
  place: "",
  twins: null,
  crazy_twins: null,
  ivf_fetus: null,
  patient_address: "",
  patient_email: "",
  factory_code: "",
  identity_card_number: "",
  medical_record_no: null,
  disease_sample: null,
  gender: null,
  urgency: null,
  other_instruction: "",
  diagnosis: "",
  disease_phase: "",
  operate_result: "",
  gpb_code: "",
  sample_day: "",
  position: "",
  discover_time: "",
  treatment: "",
  acc_name: "",
  acc_contact_no: null,
  acc_address: "",
  acc_email: "",
  paid_by: null,
  billed_to: "",
  bill_acc_no: null,
  bill_address: "",
  bill_phone: null,
  bill_email: "",
  sample_collection_date_time: null,
  x_ray_date: null,
  sample_id: null,
  sample_type: null,
  fixative: null,
  other_fixative: "",
  body_site: "",
  official_stamp: "",
  solid_tumour_type: [],
  solid_tumour_stage: null,
  stage_relapse: "",
  haematological_tumour_type: [],
  additional_notes: "",
  test_selection: [],
  other_test_description: "",
  other_test_info: {},
  authorised_content: "",
  authorised_status: null,
  authorised_name: "",
  authorised_date: null,
  authorised_reject_reason: "",
  marketing_content: "",
  marketing_name: "",
  marketing_date: null,
  marketing_remark: "",
  patient_name: "",
  patient_id: null,
  completed_by: "",
  legal_guardian_name: "",
  legal_guardian_ic: "",
  legal_guardian_date: null,
  legal_guardian_signature: "",
  legal_guardian_confirm: "",
  counsellor_name: "",
  counsellor_name_2: "",
  counsellor_date: "",
  counsellor_signature: "",
  counsellor_confirm: "",
  list_primary: "",
  relapse: false,
  sample_requirement: [],
  shipping_instructions: "",
  office_content: "",
  // This line is about the NIPT experiment
  nipt_24: null,
  nipt_5: null,
  nipt_4: null,
  nipt_3: null,
  nipt: null,
  // This line is about the NIPT experiment
  // Signing agreement day
  signing_day_patient: null,
  signing_day_doctor: null,
  // Signing agreement day
  // agreement on both sides
  agreement: "",
  twins_checkbox: "",
  // agreement on both sides
  // The name of doctors and the mothers
  mother_name: "",
  doctor_name: ""
  // The name of doctors and the mothers
};

const optionsEditor = {
  placeholder: "",
  theme: "snow"
};

export default {
  props: {
    productData: Object,
    createRequisitionFormCount: Number,
    formCount: Number,
    formData: Object
  },
  components: { quillEditor },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      optionsEditor
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    createRequisitionFormCount() {
      this.handleCreateRequisitionForm();
    },
    formCount(count) {
      if (!count) return;
      this.handleGetFormData();
    },
    orders: {
      deep: true,
      handler(data) {
        if (data?.viewDetail) {
          this.handleMapData(data?.viewDetail);
        }
      }
    }
  },
  mounted() {
    if (this.orders?.viewDetail) {
      this.handleMapData(this.orders.viewDetail, this.formData);
    }
  },
  methods: {
    handleChangeSolidTumourType(isChecked, index) {
      if (isChecked) {
        this.form.solid_tumour_type.push(index);
      } else {
        this.form.solid_tumour_type = this.form.solid_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleChangeHaematologicalTumourType(isChecked, index) {
      if (isChecked) {
        this.form.haematological_tumour_type.push(index);
      } else {
        this.form.haematological_tumour_type = this.form.haematological_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleCreateRequisitionForm() {
      if (this.createRequisitionFormCount) {
        this.$emit("onCreate", this.form);
      } else {
        this.form = { ...DEFAULT_FORM };
      }
    },
    handleMapData(data, formData) {
      if (formData) {
        this.form = { ...this.form, ...formData };
      }
      const patientInfo =
        data?.order_contact?.find(item => item?.type === 2) ||
        data?.order_contact?.find(item => item?.type === 1);
      this.form.full_name = formData?.full_name || patientInfo?.name;
      this.form.patient_address = formData?.patient_address ||
        patientInfo?.address1 ||
        patientInfo?.address2;
      this.form.phone_number = formData?.phone_number || patientInfo?.phone;
      this.form.patient_email = formData?.patient_email || patientInfo?.email;
    },
    handleGetFormData() {
      this.$emit("onChangeFormData", this.form);
    },
    handleFormatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>
    
<style lang="scss" scoped>
.title-form {
  font-size: 24px;
  color: black;
  text-align: center;
}

.cs-flex-3 {
  display: flex;
  flex-direction: row;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-flex-extra {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}
.cs-label-extra {
  width: 300px;
}
.cs-container {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.flex {
  // this is for the last two checkboxes
  margin-left: 15px;
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-flex-2 {
  display: flex;
  flex-direction: row;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.left-table {
  display: flex;
  flex-direction: column;
}

.right-table {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.cs-label {
  width: 150px;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.cs-none {
  pointer-events: none;
}
</style>