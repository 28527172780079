import axios from "@axios"
import Vue from "vue"

export default {
  namespaced: true,
  state: {
    isLoadingDetail: false,
    viewDetail: {},
    requisitionFormV2: {},
    requisitionForm: {},
    consentForm: {},
    payloadOrders: [],
    temporaryPayload: [],
    filterParams: {},
    listOrdersInPage: []
  },
  getters: {},
  mutations: {
    updateLoading(state, isLoading) {
      state.isLoadingDetail = isLoading
    },
    updateViewDetail(state, data) {
      state.viewDetail = data
    },
    updateRequisitionForm(state, data) {
      state.requisitionForm = data
    },
    updateRequisitionFormV2(state, data) {
      state.requisitionFormV2 = data
    },
    updateConsentForm(state, data) {
      state.consentForm = data
    },
    updatePayloadOrders(state, data) {
      state.payloadOrders = data
    },
    updateTemporaryPayload(state, data) {
      state.temporaryPayload = data
    },
    updateFilterList(state, data) {
      state.filterParams = data
    },
    SET_LIST_ORDER_IN_PAGE(state, data) {
      state.listOrdersInPage = data
    }
  },
  actions: {
    fetchOrdersList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getOrders(params)
          .then((result) => {
            const res = result.data
            // if (result?.status === 200) {
            //   ctx.commit("setProductStore", res?.data);
            //   ctx.commit("setProductMeta", res?.page);
            // }
            resolve({
              success: true,
              result: res,
            })
          })
          .catch((error) => {
            reject(error.message)
          })
      })
    },
    fetchOrdersById(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDetailOrder(id, params)
          .then((result) => {
            const res = result.data
            // if (result?.status === 200) {
            //   ctx.commit("setProductStore", res?.data);
            //   ctx.commit("setProductMeta", res?.page);
            // }
            resolve({
              success: true,
              result: res,
            })
          })
          .catch((error) => {
            reject(error.message)
          })
      })
    },
    paymentTransactionSlipOff(ctx, { tx_code }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .paymentTransactionSlipOff(tx_code)
          .then((result) => {
            const res = result.data
            resolve({
              success: true,
              result: res,
            })
          })
          .catch((error) => {
            reject(error.message)
          })
      })
    },
    addOrders(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/orders", { data })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateStatusOrder(ctx, params) {
      ctx.commit("updateLoading", true)
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .postUpdateStatusOrder(params)
          .then((result) => resolve(result))
          .catch((error) => {
            reject(error.message)
          })
          .finally(() => {
            ctx.commit("updateLoading", false)
          })
      })
    },
    updateAttachmentResult(ctx, { params }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .postUpdateAttachmentResult(params)
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            reject(error.message)
          })
          .finally(() => {
            ctx.commit("updateLoading", false)
          })
      })
    },
  },
}
