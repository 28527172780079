import * as config from "@/utils/config"

import Vue from "vue"
import defaultImage from "@/assets/images/default.jpg"
import slugify from "slugify"
import { isMobile, isTablet } from "mobile-device-detect"

const appUtils = {
  setLocalToken(token) {
    localStorage.setItem(config.TOKEN_KEY, token)
  },
  setLocalTokenExpiration(expiration) {
    localStorage.setItem(config.TOKEN_EXPIRATION_KEY, expiration)
  },
  setLocalUser(user) {
    localStorage.setItem(config.USER, user)
  },
  getLocalToken() {
    return localStorage.getItem(config.TOKEN_KEY)
  },
  getLocalTokenExpiration() {
    return localStorage.getItem(config.TOKEN_EXPIRATION_KEY)
  },
  getLocalUser() {
    return JSON.parse(localStorage.getItem(config.USER))
  },
  removeLocalToken() {
    localStorage.removeItem(config.TOKEN_KEY)
  },
  removeLocalTokenExpiration() {
    localStorage.removeItem(config.TOKEN_EXPIRATION_KEY)
  },
  removeLocalUser() {
    localStorage.removeItem(config.USER)
  },
  removeAll() {
    sessionStorage.clear()
    this.removeLocalToken()
    this.removeLocalTokenExpiration()
    this.removeLocalUser()
  },
  numberFormat(number, currencyUnit) {
    let data = number
      ?.toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // let data = number.toLocaleString(undefined, { minimumFractionDigits: 0 });
    if (currencyUnit) {
      data += ` ${currencyUnit}`
    }
    return data
  },
  removeVietnameseTones(str) {
    if (!str) return ""
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    str = str.replace(/đ/g, "d")
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
    str = str.replace(/Đ/g, "D")
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "") // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, "") // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ")
    str = str.trim()
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
    return str
  },
  slugifyStr(str, replacement = "-") {
    if (!str || !str.length || typeof str === "undefined") return
    return slugify(str, {
      replacement, // replace spaces with replacement character, defaults to `-`
      remove: /[*+~.()'"!:@]/g, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      locale: "vi", // language code of the locale to use
    })
  },
  randomCode(length = 6) {
    return new Array(length).join().replace(/(.|$)/g, function () {
      return ((Math.random() * 36) | 0)
        .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    })
  },
  onHandleErrorLoadImage(e) {
    e.target.src = defaultImage
    e.onerror = null
  },
  truncateHash(string, startLength = 4, endLength = 4) {
    if (!string) return ""
    return `${string.substring(0, startLength)}...${string.substring(
      string.length - endLength
    )}`
  },
  formatDate(dateTime) {
    if (!dateTime) return Vue.prototype.moment().format("DD/MM/YYYY")
    return Vue.prototype.moment(dateTime).format("DD/MM/YYYY")
  },
  formatDateTime(dateTime) {
    if (!dateTime) return Vue.prototype.moment().format("HH:mm DD/MM/YYYY")
    return Vue.prototype.moment(dateTime).format("HH:mm DD/MM/YYYY")
  },
  formatUnixTime(dateTime) {
    if (!dateTime) return ""
    return Vue.prototype.moment.unix(dateTime).format("HH:mm DD/MM/YYYY")
  },
  formatUnixTimeToDate(dateTime) {
    if (!dateTime) return ""
    return Vue.prototype.moment.unix(dateTime).format("DD/MM/YYYY")
  },
  uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
      .replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
      .replace(/-/g, "")
  },
  getImageURL(path) {
    if (path.includes('https')) return path
    return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
  },
  debounce(func, wait, immediate) {
    let timeout

    return function () {
      let context = this
      let args = arguments
      let callNow = immediate && !timeout

      clearTimeout(timeout)

      timeout = setTimeout(function () {
        timeout = null

        if (!immediate) {
          func.apply(context, args)
        }
      }, wait)
      if (callNow) func.apply(context, args)
    }
  },
  numberToWords(number) {
    const dvBlock = "1 nghìn triệu tỷ".split(" ")

    var str = parseInt(number) + ""
    var i = 0
    var arr = []
    var index = str.length
    var result = []
    var rsString = ""

    if (index == 0 || str == "NaN") {
      return ""
    }

    while (index >= 0) {
      arr.push(str.substring(index, Math.max(index - 3, 0)))
      index -= 3
    }
    for (i = arr.length - 1; i >= 0; i--) {
      if (arr[i] != "" && arr[i] != "000") {
        result.push(convert_block_three(arr[i]))
        if (dvBlock[i]) {
          result.push(dvBlock[i])
        }
      }
    }
    rsString = result.join(" ")

    return (
      rsString.replace(/[0-9]/g, "").replace(/ /g, " ").replace(/ $/, "") +
      " đồng"
    )
  },
  checkIsMobile() {
    return isMobile
  },
  checkIsMobileAndTablet() {
    return isMobile || isTablet
  },
  formatNumber(num) {
    return new Intl.NumberFormat('de-DE').format(num);
  },
}


const defaultNumbers = " hai ba bốn năm sáu bảy tám chín"

const chuHangDonVi = ("1 một" + defaultNumbers).split(" ")
const chuHangChuc = ("lẻ mười" + defaultNumbers).split(" ")
const chuHangTram = ("không một" + defaultNumbers).split(" ")

const convert_block_three = (number) => {
  if (number == "000") return ""
  var _a = number + ""

  switch (_a.length) {
    case 0:
      return ""
    case 1:
      return chuHangDonVi[_a]
    case 2:
      return convert_block_two(_a)
    case 3:
      var chuc_dv = ""
      if (_a.slice(1, 3) != "00") {
        chuc_dv = convert_block_two(_a.slice(1, 3))
      }
      var tram = chuHangTram[_a[0]] + " trăm"
      return tram + " " + chuc_dv
  }
}

const convert_block_two = (number) => {
  var dv = chuHangDonVi[number[1]]
  var chuc = chuHangChuc[number[0]]
  var append = ""
  if (number[0] > 0 && number[1] == 5) {
    dv = "lăm"
  }
  if (number[0] > 1) {
    append = " mươi"
    if (number[1] == 1) {
      dv = " mốt"
    }
  }

  return chuc + "" + append + " " + dv
}


export default appUtils
