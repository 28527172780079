<template>
  <div>
    <span class="title-form"
      >PHIẾU ĐỒNG THUẬN THỰC HIỆN XÉT NGHIỆM K-TRACK</span
    >
    <div class="mt-3">
      <div id="printOrderForm" class="mt-3">
        <!-- PAGE 1 -->
        <div>
          <!-- COLUMN LEFT -->
          <div>
            <!-- PATIENT INFORMATION -->
            <div>
              <span class="cs-title"
                >Phần 1: Thông tin người làm xét nghiệm</span
              >
              <div class="cs-flex">
                <span class="cs-label">Họ tên:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.full_name" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Ngày sinh:</span>
              </div>
              <div class="cs-flex">
                <b-form-input
                  type="date"
                  class="cs-content"
                  v-model="form.birthday"
                />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Giới tính</span>
                <div class="d-flex cs-content">
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isMale = form.gender === 1)"
                  >
                    <input
                      v-model="isMale"
                      id="male"
                      name="male"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.gender = 1)
                            : (form.gender = null)
                      "
                    />
                    <label>Nam</label>
                  </div>
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isFemale = form.gender === 2)"
                  >
                    <input
                      v-model="isFemale"
                      id="female"
                      name="female"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.gender = 2)
                            : (form.gender = null)
                      "
                    />
                    <label>Nữ</label>
                  </div>
                </div>
              </div>
              <div class="cs-flex">
                <span class="cs-label">Địa chỉ</span>
              </div>
              <div class="cs-flex">
                <b-form-input
                  class="cs-content"
                  v-model="form.patient_address"
                />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Điện thoại:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.patient_phone" />
              </div>

              <div class="cs-flex">
                <span class="cs-label">Bác Sĩ:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.doctor" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">PK/BV:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.hospital" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Điện thoại</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.doctor_phone" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Email</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.doctor_email" />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Ngày lấy mẫu:</span>
              </div>
              <div class="cs-flex">
                <b-form-input
                  type="date"
                  class="cs-content"
                  v-model="form.sample_collection_date_time"
                />
              </div>
              <div class="cs-flex">
                <span class="cs-label">Mã XN:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.factory_code" />
              </div>
            </div>
            <!-- PATIENT INFORMATION -->
            <!-- REFERRING PHYSICIAN INFORMATION -->
            <div>
              <span class="cs-title">PHẦN 2. LỰA CHỌN XÉT NGHIỆM</span>
              <div class="cs-flex mb-6">
                <div class="d-flex cs-content">
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isG4500 = form.choice === 1)"
                  >
                    <input
                      v-model="isG4500"
                      id="g4500"
                      name="g4500"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.choice = 1)
                            : (form.choice = null)
                      "
                    />
                    <label>G4500</label>
                  </div>
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isG4500Family = form.choice === 2)"
                  >
                    <input
                      v-model="isG4500Family"
                      id="g4500-family"
                      name="g4500-family"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.choice = 2)
                            : (form.choice = null)
                      "
                    />
                    <label>G4500 Family</label>
                  </div>
                </div>
              </div>
              <div class="cs-flex">
                <span class="cs-label">
                  <strong>Dạng mẫu:</strong>
                </span>
              </div>
              <div class="cs-flex">
                <div class="cs-content">
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isBlood = form.sample1 === 1)"
                  >
                    <input
                      v-model="isBlood"
                      id="blood"
                      name="blood"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.sample1 = 1)
                            : (form.sample1 = null)
                      "
                    />
                    <label>máu</label>
                  </div>
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isSlime = form.sample1 === 2)"
                  >
                    <input
                      v-model="isSlime"
                      id="slime"
                      name="slime"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.sample1 = 2)
                            : (form.sample1 = null)
                      "
                    />
                    <label>dịch ối</label>
                  </div>
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isStomach = form.sample1 === 3)"
                  >
                    <input
                      v-model="isStomach"
                      id="stomach"
                      name="stomach"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.sample1 = 3)
                            : (form.sample1 = null)
                      "
                    />
                    <label>phết niêm mạc</label>
                  </div>
                </div>
              </div>
              <div class="cs-flex">
                <span class="cs-label-1">
                  <strong>*Nếu làm G4500 Family:</strong>
                </span>
              </div>
              <div class="cs-flex-2">
                <span class="cs-label-2">
                  <strong>Thông tin người thân thứ 1</strong>
                </span>
                <div class="cs-flex">
                  <span class="cs-label">Họ tên:</span>
                </div>
                <div class="cs-flex">
                  <b-form-input
                    class="cs-content"
                    v-model="form.members_name_one"
                  />
                </div>
                <div class="cs-flex">
                  <span class="cs-label">Ngày sinh:</span>
                </div>
                <div class="cs-flex">
                  <b-form-input
                    type="date"
                    class="cs-content"
                    v-model="form.birth_date_one"
                  />
                </div>
                <div class="cs-flex">
                  <span>Mối quan hệ với bệnh nhân:</span>
                </div>
                <div class="cs-flex">
                  <b-form-input
                    class="cs-content"
                    v-model="form.relationship_one"
                  />
                </div>
                <div class="cs-flex">
                  <span class="cs-label">Mô tả kiểu hình:</span>
                </div>
                <div class="cs-flex">
                  <b-form-input
                    class="cs-content"
                    v-model="form.appearance_one"
                  />
                </div>
              </div>
              <div class="cs-flex-2">
                <span class="cs-label-2">
                  <strong>Thông tin người thân thứ 2</strong>
                </span>
                <div class="cs-flex">
                  <span class="cs-label">Họ tên:</span>
                </div>
                <div class="cs-flex">
                  <b-form-input
                    class="cs-content"
                    v-model="form.members_name_two"
                  />
                </div>
                <div class="cs-flex">
                  <span class="cs-label">Ngày sinh:</span>
                </div>
                <div class="cs-flex">
                  <b-form-input
                    type="date"
                    class="cs-content"
                    v-model="form.birth_date_two"
                  />
                </div>
                <div class="cs-flex">
                  <span>Mối quan hệ với bệnh nhân:</span>
                </div>
                <div class="cs-flex">
                  <b-form-input
                    class="cs-content"
                    v-model="form.relationship_two"
                  />
                </div>
                <div class="cs-flex">
                  <span class="cs-label">Mô tả kiểu hình:</span>
                </div>
                <div class="cs-flex">
                  <b-form-input
                    class="cs-content"
                    v-model="form.appearance_two"
                  />
                </div>
              </div>
            </div>
            <!-- REFERRING PHYSICIAN INFORMATION -->
            <!-- ACCOUNT INFORMATION -->
            <!-- <div>
                <span class="cs-title">PHẦN 3. THỎA THUẬN SỬ DỤNG DỊCH VỤ</span>
              </div>-->
            <!-- ACCOUNT INFORMATION -->

            <!-- SPECIMEN INFORMATION -->
            <div>
              <span class="cs-title">PHẦN 4. THÔNG TIN LÂM SÀNG</span>

              <div class="cs-flex-3">
                <span class="cs-label-1">
                  <strong
                    >Bệnh nhân hoặc thành viên trong gia đình có ghép
                    tủy?</strong
                  >
                </span>
                <div class="cs-flex-3 cs-content">
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isSpine = form.spine === 1)"
                  >
                    <input
                      v-model="isSpine"
                      id="spine"
                      name="spine"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.spine = 1)
                            : (form.spine = null)
                      "
                    />
                    <label>Không</label>
                  </div>
                </div>
                <div class="cs-flex-3">
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isNotSpine = form.spine === 2)"
                  >
                    <input
                      v-model="isNotSpine"
                      id="not-spine"
                      name="not-spine"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.spine = 2)
                            : (form.spine = null)
                      "
                    />
                    <label>Có, người được ghép tủy</label>
                  </div>
                </div>
              </div>
              <div class="cs-flex-3">
                <span class="cs-label-1">
                  <strong
                    >Bệnh nhân hoặc thành viên trong gia đình có truyền máu
                    trong vòng 2 tuần?</strong
                  >
                </span>

                <div class="cs-flex-3 cs-content">
                  <div class="cs-content">
                    <div
                      class="mr-1 cs-checkbox"
                      :set="(isTransferred = form.blood_transfer === 1)"
                    >
                      <input
                        v-model="isTransferred"
                        id="spine"
                        name="spine"
                        type="checkbox"
                        @change="
                          (e) =>
                            e.target.checked
                              ? (form.blood_transfer = 1)
                              : (form.blood_transfer = null)
                        "
                      />
                      <label>Không -</label>
                    </div>
                  </div>
                </div>
                <div class="cs-flex">
                  <div
                    class="cs-checkbox"
                    :set="(isNotTransferred = form.blood_transfer === 2)"
                  >
                    <input
                      v-model="isNotTransferred"
                      id="not-spine"
                      name="not-spine"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.blood_transfer = 2)
                            : (form.blood_transfer = null)
                      "
                    />
                    <label>Có, người được truyền máu</label>
                  </div>
                </div>
              </div>
              <div class="cs-flex-2">
                <div class="d-flex mb-1 left">
                  <div>
                    <span>
                      <strong>Mô tả tất cả triệu chứng lâm sàng:</strong>
                      <i
                        >(kết quả phân tích dựa trên thông tin lâm sàng được
                        cung cấp, vì vậy càng nhiều thông tin về lâm sàng sẽ
                        tăng khả năng tìm ra nguyên nhân bệnh)</i
                      >
                    </span>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isMoron = form.one_moron === 1)"
                  >
                    <input
                      v-model="isMoron"
                      id="moron"
                      name="moron"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.one_moron = 1)
                            : (form.one_moron = null)
                      "
                    />
                    <label>
                      <strong>Thiểu năng trí tuệ(giá trị IQ) - Tự kỷ:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isNerveMuscle = form.two_nerve_muscles === 2)"
                  >
                    <input
                      v-model="isNerveMuscle"
                      id="never-muscle"
                      name="never-muscle"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.two_nerve_muscles = 2)
                            : (form.two_nerve_muscles = null)
                      "
                    />
                    <label>
                      <strong>Thần kinh cơ</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isThree = form.three === 3)"
                  >
                    <input
                      v-model="isThree"
                      id="abnormal-shape"
                      name="abnormal-shape"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.three = 3)
                            : (form.three = null)
                      "
                    />
                    <label>
                      <strong>Bất thường kiểu hình - Tăng trưởng</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isFour = form.four === 4)"
                  >
                    <input
                      v-model="isFour"
                      id="four"
                      name="four"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.four = 4)
                            : (form.four = null)
                      "
                    />
                    <label>
                      <strong>Bất thường hộp sọ - Khung xương:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isFive = form.five === 5)"
                  >
                    <input
                      v-model="isFive"
                      id="five"
                      name="five"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.five = 5)
                            : (form.five = null)
                      "
                    />
                    <label>
                      <strong>Tim mạch:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div class="mb-1 cs-checkbox" :set="(isSix = form.six === 6)">
                    <input
                      v-model="isSix"
                      id="six"
                      name="six"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked ? (form.six = 6) : (form.six = null)
                      "
                    />
                    <label>
                      <strong>Thận - Tiết niệu:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isSeven = form.seven === 7)"
                  >
                    <input
                      v-model="isSeven"
                      id="front-cancer"
                      name="front-cancer"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.seven = 7)
                            : (form.seven = null)
                      "
                    />
                    <label>
                      <strong>Sinh dục:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isEight = form.eight === 8)"
                  >
                    <input
                      v-model="isEight"
                      id="eight"
                      name="eight"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.eight = 8)
                            : (form.eight = null)
                      "
                    />
                    <label>
                      <strong>Mắt:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isNine = form.nine === 9)"
                  >
                    <input
                      v-model="isNine"
                      id="nine"
                      name="nine"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.nine = 9)
                            : (form.nine = null)
                      "
                    />
                    <label>
                      <strong>Tai:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isTen = form.ten === 10)"
                  >
                    <input
                      v-model="isTen"
                      id="ten"
                      name="ten"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked ? (form.ten = 10) : (form.ten = null)
                      "
                    />
                    <label>
                      <strong>Miễn dịch:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isEleventh = form.eleventh === 11)"
                  >
                    <input
                      v-model="isEleventh"
                      id="eleventh"
                      name="eleventh"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.eleventh = 11)
                            : (form.eleventh = null)
                      "
                    />
                    <label>
                      <strong>Da liễu:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isTwelve = form.twelve === 12)"
                  >
                    <input
                      v-model="isTwelve"
                      id="twelve"
                      name="twelve"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.twelve = 12)
                            : (form.twelve = null)
                      "
                    />
                    <label>
                      <strong>Trao đổi chất:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isThirteen = form.thirteen === 13)"
                  >
                    <input
                      v-model="isThirteen"
                      id="thirteen"
                      name="thirteen"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.thirteen = 13)
                            : (form.thirteen = null)
                      "
                    />
                    <label>
                      <strong>Phổi:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isFourteen = form.fourteen === 14)"
                  >
                    <input
                      v-model="isFourteen"
                      id="fourteen"
                      name="fourteen"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.fourteen = 14)
                            : (form.fourteen = null)
                      "
                    />
                    <label>
                      <strong>Tiêu hóa:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isFifteen = form.fifteen === 15)"
                  >
                    <input
                      v-model="isFifteen"
                      id="fifteen"
                      name="fifteen"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.fifteen = 15)
                            : (form.fifteen = null)
                      "
                    />
                    <label>
                      <strong>Máu:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isSixteen = form.sixteen === 16)"
                  >
                    <input
                      v-model="isSixteen"
                      id="sixteen"
                      name="sixteen"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.sixteen = 16)
                            : (form.sixteen = null)
                      "
                    />
                    <label>
                      <strong>Ung thư:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isSeventeen = form.seventeen === 17)"
                  >
                    <input
                      v-model="isSeventeen"
                      id="seventeen"
                      name="seventeen"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.seventeen = 17)
                            : (form.seventeen = null)
                      "
                    />
                    <label>
                      <strong>Khác:</strong>
                    </label>
                  </div>
                </div>
                <div class="d-flex cs-content">
                  <div
                    class="mb-1 cs-checkbox"
                    :set="(isEighteen = form.eighteen === 18)"
                  >
                    <input
                      v-model="isEighteen"
                      id="eighteen"
                      name="eighteen"
                      type="checkbox"
                      @change="
                        (e) =>
                          e.target.checked
                            ? (form.eighteen = 18)
                            : (form.eighteen = null)
                      "
                    />
                    <label>
                      <strong>Không rõ:</strong>
                    </label>
                  </div>
                </div>
              </div>
              <!-- Checkboxes are here -->
            </div>
            <div>
              <span class="cs-title"
                >PHẦN 5. ĐỒNG Ý CỦA NGƯỜI LÀM XÉT NGHIỆM VÀ BÁC SĨ</span
              >
            </div>
            <div>
              <span
                >*Xét nghiệm di truyền đối với trẻ em 18 tuổi phải có sự đồng ý
                của cha mẹ hoặc người giám hộ hợp pháp. Nếu người giám hộ hợp
                pháp, vui lòng ghi rõ mối quan hệ với bệnh nhân:</span
              >
            </div>
            <div>
              <b-form-input
                class="cs-content"
                v-model="form.legal_guardian_name"
              />
            </div>
          </div>

          <!-- COLUMN RIGHT -->
        </div>
        <!-- PAGE 1 -->
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { mapState } from "vuex";

const DEFAULT_FORM = {
  spine: null,
  birthday: null,
  blood_transfer: null,
  choice: null,
  full_name: "",
  hospital: "",
  doctor: "",
  patient_email: "",
  patient_phone: "",
  doctor_phone: "",
  doctor_email: "",
  factory_code: "",
  identity_card_number: "",
  medical_record_no: null,
  disease_sample: null,
  sample1: null,
  sample2: null,
  sample3: null,
  gender: null,
  urgency: null,
  other_instruction: "",
  diagnosis: "",
  disease_phase: "",
  operate_result: "",
  gpb_code: "",
  sample_day: "",
  position: "",
  discover_time: "",
  treatment: "",
  acc_name: "",
  acc_contact_no: null,
  acc_address: "",
  acc_email: "",
  paid_by: null,
  billed_to: "",
  bill_acc_no: null,
  bill_address: "",
  bill_phone: null,
  bill_email: "",
  sample_collection_date_time: null,
  sample_id: null,
  sample_type: null,
  fixative: null,
  other_fixative: "",
  body_site: "",
  official_stamp: "",
  solid_tumour_type: [],
  solid_tumour_stage: null,
  stage_relapse: "",
  haematological_tumour_type: [],
  additional_notes: "",
  test_selection: [],
  other_test_description: "",
  other_test_info: {},
  authorised_content: "",
  authorised_status: null,
  authorised_name: "",
  authorised_date: null,
  authorised_reject_reason: "",
  marketing_content: "",
  marketing_name: "",
  marketing_date: null,
  marketing_remark: "",
  patient_name: "",
  patient_id: null,
  completed_by: "",
  legal_guardian_name: "",
  legal_guardian_ic: "",
  legal_guardian_date: null,
  legal_guardian_signature: "",
  legal_guardian_confirm: "",
  counsellor_name: "",
  counsellor_name_2: "",
  counsellor_date: "",
  counsellor_signature: "",
  counsellor_confirm: "",
  list_primary: "",
  relapse: false,
  sample_requirement: [],
  shipping_instructions: "",
  office_content: "",
  // This line contains 18 causes, from top to bottom
  one_moron: null,
  two_nerve_muscles: null,
  three: null,
  four: null,
  five: null,
  six: null,
  seven: null,
  eight: null,
  nine: null,
  ten: null,
  eleventh: null,
  twelve: null,
  thirteen: null,
  fourteen: null,
  fifteen: null,
  sixteen: null,
  seventeen: null,
  eighteen: null,
  // This line contains 18 causes
  // This line is for family members information
  members_name_one: "",
  birth_date_one: null,
  relationship_one: "",
  appearance_one: "",
  members_name_two: "",
  birth_date_two: null,
  relationship_two: "",
  appearance_two: "",
  // This line is for family members information
};

const optionsEditor = {
  placeholder: "",
  theme: "snow",
};

export default {
  props: {
    productData: Object,
    createRequisitionFormCount: Number,
    formCount: Number,
    formData: Object,
  },
  components: { quillEditor },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      optionsEditor,
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
    }),
  },
  watch: {
    createRequisitionFormCount() {
      this.handleCreateRequisitionForm();
    },
    formCount(count) {
      if (!count) return;
      this.handleGetFormData();
    },
    orders: {
      deep: true,
      handler(data) {
        if (data?.viewDetail) {
          this.handleMapData(data?.viewDetail);
        }
      },
    },
  },
  mounted() {
    if (this.orders?.viewDetail) {
      this.handleMapData(this.orders.viewDetail, this.formData);
    }
  },
  methods: {
    handleChangeSolidTumourType(isChecked, index) {
      if (isChecked) {
        this.form.solid_tumour_type.push(index);
      } else {
        this.form.solid_tumour_type = this.form.solid_tumour_type.filter(
          (item) => item !== index
        );
      }
    },
    handleChangeHaematologicalTumourType(isChecked, index) {
      if (isChecked) {
        this.form.haematological_tumour_type.push(index);
      } else {
        this.form.haematological_tumour_type =
          this.form.haematological_tumour_type.filter((item) => item !== index);
      }
    },
    handleCreateRequisitionForm() {
      if (this.createRequisitionFormCount) {
        this.$emit("onCreate", this.form);
      } else {
        this.form = { ...DEFAULT_FORM };
      }
    },
    handleMapData(data, formData) {
      if (formData) {
        this.form = { ...this.form, ...formData };
      }
      const patientInfo =
        data?.order_contact?.find((item) => item?.type === 2) ||
        data?.order_contact?.find((item) => item?.type === 1);
      this.form.full_name = formData?.full_name || patientInfo?.name;
      this.form.patient_address =
        formData?.patient_address ||
        patientInfo?.address1 ||
        patientInfo?.address2;
      this.form.patient_phone = formData?.patient_phone || patientInfo?.phone;
      this.form.email = formData?.email || patientInfo?.email;
    },
    handleGetFormData() {
      this.$emit("onChangeFormData", this.form);
    },
    handleFormatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.title-form {
  font-size: 24px;
  color: black;
  text-align: center;
}

.cs-container {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-flex-2 {
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
  // align-items: center;
}

.cs-flex-3 {
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
  // align-items: center;
}

.cs-flex-4 {
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
  margin-left: 3rem;
}
.cs-flex-master {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.cs-flex-extra {
  display: flex;
  flex-direction: row;
  padding: 6px;
  box-sizing: border-box;
  // align-items: center;
}
.left-table {
  display: flex;
  flex-direction: column;
}

.right-table {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.cs-label {
  width: 150px;
}

.cs-label-1 {
  width: 100%;
  margin-left: 10px;
  margin-bottom: 10px;
}

.cs-label-2 {
  width: 100%;
  margin-bottom: 10px;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.cs-none {
  pointer-events: none;
}
</style>
