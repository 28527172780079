import BaseRequest from "../BaseRequest"
import appUtils from "../../utils/appUtils"

export default class MarketplaceRequest extends BaseRequest {
  getUnits(params) {
    return this.get(`go/ecom/unit`, params)
  }
  getSpecifications(params) {
    return this.get(`go/ecom/specification`, params)
  }
  getDosageForms(params) {
    return this.get(`go/ecom/dosage_form`, params)
  }
  getManufacturers(params) {
    return this.get(`go/ecom/manufacturer`, params)
  }
  getOrigins(params) {
    return this.get(`go/ecom/origin`, params)
  }
  getBrands(params) {
    return this.get(`go/ecom/brand`, params)
  }
  getCategories(params) {
    return this.get(`go/ecom/category`, params)
  }
  getProductDetailById(id) {
    return this.getLocal(`go/ecom/products/supplier/${id}`)
  }
  createProduct(params) {
    return this.postLocal(`go/ecom/products/create`, params)
  }
  updateProduct(id, params) {
    return this.putLocal(`go/ecom/products/${id}`, params)
  }
  getWarehouses(params) {
    return this.getLocal(`go/ecom/warehouse/list`, params)
  }
  createWarehouse(data) {
    return this.postLocal(`go/ecom/warehouse`, data)
  }
  updateWarehouse(id, params, data) {
    return this.putLocal(`go/ecom/warehouse/${id}?${new URLSearchParams(params).toString()}`, data)
  }
  deleteWarehouse(id) {
    return this.deleteLocal(`go/ecom/warehouse/${id}`)
  }
}
