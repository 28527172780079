import Vue from "vue";

export default {
  namespaced: true,
  state: {
    campaignsList: [],
    campaignsMeta: {},
    listCampaignsInPage: []
  },
  getters: {},
  mutations: {
    setcampaignStore(state, data) {
      state.campaignsList = data;
    },
    setcampaignMeta(state, data) {
      state.campaignsMeta = data;
    },
  },
  actions: {
    getCampaignsList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .getCampains(params)
          .then((result) => {
            const res = result.data;
            if (result?.status === 200) {
              ctx.commit("setcampaignStore", res?.data);
              ctx.commit("setcampaignMeta", res?.page);
            }
            resolve({
              success: true,
              result: res,
            });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    addCampaign(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .addCampaign(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCampaignById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .getDetailCampaign(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateCampaign(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .updateCampaign(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    SET_LIST_CAMPAIGN_IN_PAGE(state, data) {
      state.listCampaignsInPage = data
    }
  },
};
