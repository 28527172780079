import store from '@/store/index'

export default async function ({ to, from, next }) {
  if (!store.state.authenticate.token) {
    await store.dispatch('authenticate/initAuth')
    if (!store.state.authenticate.token) {
      return next({ name: 'login' })
    }
  }
  return next()
}
