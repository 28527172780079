import Vue from "vue";

export default {
  namespaced: true,
  state: {
    productStore: [],
    productMeta: {},
  },
  getters: {},
  mutations: {
    setProductStore(state, data) {
      state.productStore = data;
    },
    setProductMeta(state, data) {
      state.productMeta = data;
    },
  },
  actions: {
    fetchReceivings(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getProducts(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProductById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getProductById(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .addProduct(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProduct(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .updateProduct(id, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProductIntoInventory(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .addProductIntoInventory(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProductCertificates(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getProductCertificates(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getOrderFormConfigs(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getOrderFormConfigs(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
