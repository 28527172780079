import Vue from "vue";

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {},
  actions: {
    fetchStockIn(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getWarehouseStockin(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createStockin(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .createStockIn(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStockInById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDetailStockIn(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
