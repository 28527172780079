import Vue from 'vue'
import VueCompositionAPI from "@vue/composition-api"
Vue.use(VueCompositionAPI)
import { createAcl, defineAclRules } from 'vue-simple-acl'
import store from '@/store/index'
import router from '../router'

const user = () => {
    const common = true
    const permissions = store.state.authenticate.permissions?.map(elm => elm?.permission_name) || []
    return {
        common,
        permissions
    }
}
const rules = () => defineAclRules(async setRule => {
    // const response = await store.dispatch('authenticate/getAllPermissions')
    // const listPermissions = response.data?.map(elm => elm.permission_name) || []
    setRule('common', user => user().common)
    setRule('service-orders', user => user().permissions.includes('service-orders'))
    setRule('service-management', user => user().permissions.includes('service-management'))
    setRule('service-result', user => user().permissions.includes('service-result'))
    setRule('report', user => user().permissions.includes('report'))
    setRule('configs', user => user().permissions.includes('configs'))
    setRule('product-orders', user => user().permissions.includes('product-orders'))
    setRule('campaigns', user => user().permissions.includes('campaigns'))
    setRule('product-management', user => user().permissions.includes('product-management'))
    setRule('processor-service-orders', user => user().permissions.includes('processor-service-orders'))
    setRule('processor-service-result', user => user().permissions.includes('processor-service-result'))
    // listPermissions?.forEach(item => {
    //     setRule(`${item}`, user => {
    //         return user().permissions?.includes(`${item}`)
    //     })
    // })
})

const simpleAcl = createAcl({
    user,
    rules,
    router,
})

export default simpleAcl