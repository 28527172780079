import i18n from "@/libs/i18n"
import auth from "@/middleware/auth"
// import {  } from "@/middleware/check-roles"
import { PRODUCT_TYPES } from "@/utils/constant"

export const eCommerceRoutes = [
  // Product & Services
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/Products/index.vue"),
    meta: {
      can: 'product-management',
      type: PRODUCT_TYPES.PRODUCT,
      pageTitle: i18n.t("lbl_product_category"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/add",
    name: "add-product",
    component: () => import("@/views/Products/Add/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: "lbl_add_product",
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_product___services",
          active: false,
          to: "/products",
        },
        {
          text: "lbl_add_new",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/:slug",
    name: "update-product",
    component: () => import("@/views/Products/Add/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: "lbl_update",
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_product___services"),
          active: false,
          to: "/products",
        },
        {
          text: "lbl_update_product_&_service",
          active: true,
        },
      ],
    },
  },
  {
    path: "/marketplace",
    name: "marketplace",
    component: () => import("@/views/Inventory/index.vue"),
    meta: {
      can: 'product-management',
      type: PRODUCT_TYPES.PRODUCT,
      pageTitle: i18n.t("lbl_marketplace"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  // Product
  {
    path: "/inventory-product",
    name: "inventory-product",
    component: () => import("@/views/Marketplace/InventoryProduct/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_inventory_product"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_list"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/inventory-product/:id",
    name: "ProductInventoryDetail",
    component: () =>
      import("@/views/Marketplace/InventoryProduct/ProductInventoryDetail.vue"),
    meta: {
      can: 'product-management',
      pageTitle: "Chi tiết lô",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Danh sách",
          active: false,
          to: "/inventory-product",
        },
        {
          text: "Chi tiết",
          active: true,
        },
      ],
    },
  },
  /* Marketplace Product List */
  {
    path: "/products-marketplace",
    name: "products-marketplace",
    component: () => import("@/views/ProductsMarketplace/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_products"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_list"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/products-marketplace/add",
    name: "add-product-marketplace",
    component: () => import("@/views/ProductsMarketplace/Add/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: "lbl_add_product",
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_product___services",
          active: false,
          to: "/products-marketplace",
        },
        {
          text: "lbl_add_new",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products-marketplace/update/:id",
    name: "product-update",
    component: () => import("@/views/ProductsMarketplace/Add/index.vue"),
    meta: {
      can: 'product-management',
      isEditable: true,
      pageTitle: "lbl_product_detail",
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_product___services",
          active: false,
          to: "/products-marketplace",
        },
        {
          text: "lbl_product_detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products-marketplace/:id",
    name: "product-detail",
    component: () => import("@/views/ProductsMarketplace/Add/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: "lbl_product_detail",
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_product___services",
          active: false,
          to: "/products-marketplace",
        },
        {
          text: "lbl_product_detail",
          active: true,
        },
      ],
    },
  },
  // Kho
  {
    path: "/warehouse",
    name: "warehouse",
    component: () => import("@/views/Warehouse/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_warehouse"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  /* End of Marketplace Product List */
  {
    path: "/product-category",
    name: "product-category",
    component: () => import("@/views/Products/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_product_category"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_list"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/bill-manager/import",
    name: "import",
    component: () => import("@/views/Marketplace/BillManager/importBill.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_document_management"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_list_import_bill"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/bill-manager/export",
    name: "export",
    component: () => import("@/views/Marketplace/BillManager/exportBill.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_document_management"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_list_export_bill"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/credit/config",
    name: "ConfigCredit",
    component: () => import("@/views/HODOCredit/CreditConfig/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Hệ thống điểm thưởng"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Thiết lập tỷ lệ"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/credit/accumulate",
    name: "accumulate",
    component: () => import("@/views/HODOCredit/Accumulate/Accumulation.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Chương trình tích điểm"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Lịch sử tích điểm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/credit/accumulate/add",
    name: "CreateAccumulateTransaction",
    component: () =>
      import("@/views/HODOCredit/Accumulate/CreateAccumulateTransaction.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Chương trình tích điểm"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Tích điểm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/credit/spend-point",
    name: "spend",
    component: () => import("@/views/HODOCredit/Spend/SpendPoint.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Chương trình tiêu điểm"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Lịch sử tiêu điểm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/credit/spend-point/add",
    name: "CreateSpendPointTransaction",
    component: () =>
      import("@/views/HODOCredit/Spend/CreateSpendPointTransaction.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Chương trình tiêu điểm"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Tiêu điểm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/credit/deposit",
    name: "deposit",
    component: () => import("@/views/HODOCredit/TopUp/TopUpPoint.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Chương trình nạp điểm"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Chương trình nạp điểm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/credit/deposit/add",
    name: "CreateTopUpPointTransaction",
    component: () =>
      import("@/views/HODOCredit/TopUp/CreateTopUpPointTransaction.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Chương trình nạp điểm"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Tạo chương trình nạp điểm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/credit/deposit-point",
    name: "DepositPoint",
    component: () =>
      import("@/views/HODOCredit/TopUp/DepositPoint.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Chương trình nạp điểm"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Nạp điểm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/indication",
    name: "indication",
    component: () => import("@/views/Indication/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("Phiếu thu"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("Danh sách"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/indication/:id",
    name: "indication-detail",
    component: () => import("@/views/OrdersMarketPlace/Detail/Components/ModalDetailIndication.vue"),
    meta: {
      can: 'product-management',
      middleware: [auth,],
      layout: 'full'
    },
  },
  {
    path: "/receipt-manager",
    name: "receipt-manager",
    component: () => import("@/views/Marketplace/ReceiptManager/index.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_receipt"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_list"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/receipt/:id",
    name: "ReceiptDetail",
    component: () =>
      import("@/views/Marketplace/ReceiptManager/ReceiptDetail.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_receipt_detail"),
      middleware: [auth,],
      layout: "full",
    },
  },
  {
    path: "/add-receiving",
    name: "AddReceiving",
    component: () =>
      import("@/views/Marketplace/ReceivingManager/AddReceiving.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_import_receiving"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_import_receiving"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/receiving/:id",
    name: "ReceivingDetail",
    component: () =>
      import("@/views/Marketplace/ReceivingManager/ReceivingDetail.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_receiving_detail"),
      middleware: [auth,],
      layout: "full",
    },
  },
  {
    path: "/add-saling",
    name: "AddSaling",
    component: () => import("@/views/Marketplace/SalingManager/AddSaling.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_add_saling"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_add_saling"),
          active: true,
        },
      ],
    },
  },
  // config  product
  {
    path: "/config-specification",
    name: `config-specification`,
    component: () => import("@/views/Configs/Product/specification.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_category_management"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-dosage-form",
    name: `config-dosage-form`,
    component: () => import("@/views/Configs/Product/dosage-form.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_category_management"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-unit-manager",
    name: `config-unit-manager`,
    component: () => import("@/views/Configs/Product/unit-manager.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_category_management"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-category",
    name: `config-category`,
    component: () => import("@/views/Configs/Product/category.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_category_management"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-manufacture-manager",
    name: `config-manufacture-manager`,
    component: () => import("@/views/Configs/Product/manufacture-manager.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_category_management"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-brand-manager",
    name: `config-brand-manager`,
    component: () => import("@/views/Configs/Product/brand-manager.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_category_management"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-provider-manager",
    name: `config-provider-manager`,
    component: () => import("@/views/Configs/Product/provider-manager.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_category_management"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config-origin",
    name: `config-origin`,
    component: () => import("@/views/Configs/Product/origin.vue"),
    meta: {
      can: 'configs',
      pageTitle: i18n.t("lbl_category_management"),
      middleware: [auth],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/saling/:id",
    name: "SalingDetail",
    component: () =>
      import("@/views/Marketplace/SalingManager/SalingDetail.vue"),
    meta: {
      can: 'product-management',
      pageTitle: i18n.t("lbl_saling_detail"),
      middleware: [auth,],
      layout: "full",
    },
  },
  // Orders marketplace
  {
    path: "/orders-market-place",
    name: "orders-market-place",
    component: () => import("@/views/OrdersMarketPlace/index.vue"),
    meta: {
      can: 'product-orders',
      pageTitle: "Quản lý đơn hàng hóa",
      middleware: [auth,],
      breadcrumb: [
        {
          text: "lbl_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders-market-place/:id",
    name: "orders-market-place-detail",
    component: () => import("@/views/OrdersMarketPlace/Detail/index.vue"),
    meta: {
      can: 'product-orders',
      pageTitle: i18n.t("lbl_orders_detail"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_orders"),
          active: false,
          to: "/orders-market-place",
        },
        {
          text: "lbl_detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: () => import("@/views/Campaigns/index.vue"),
    meta: {
      can: 'campaigns',
      pageTitle: i18n.t("lbl_campaigns_detail"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_campaigns"),
          active: false,
          to: "/campaigns",
        },
        {
          text: "lbl_detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/campaigns/add",
    name: "campaigns-id",
    component: () => import("@/views/Campaigns/_id/index.vue"),
    meta: {
      can: 'campaigns',
      pageTitle: i18n.t("lbl_campaigns_add"),
      middleware: [auth,],
      breadcrumb: [
        {
          text: i18n.t("lbl_campaigns"),
          active: false,
          to: "/campaigns",
        },
        {
          text: "lbl_add_new",
          active: true,
        },
      ],
    },
  },
]
