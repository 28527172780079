import store from '@/store/index'

export default async function checkLogin({
  to, from,
  next,
}) {
  await store.dispatch('authenticate/initAuth')
  if (store.state.authenticate.token) { return next({ name: 'home' }) }
  return next()
}
