import Vue from "vue";

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {},
  actions: {
    fetchProductVariant(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getProductVariant(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
