import Vue from "vue";

export default {
  namespaced: true,
  state: {
    statusInventoryOptions: [
      {
        label: "Còn hàng",
        value: 3,
      },
      {
        label: "Sắp hết",
        value: 2,
      },
      {
        label: "Đã hết",
        value: 1,
      },
    ],
  },
  getters: {
    statusInventoryOptions: (state) => state.statusInventoryOptions,
  },
  mutations: {},
  actions: {
    fetchInventoryProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getWarehouseProduct(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchInventoryProductLot(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getWarehouseProductLot(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchInventories(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getWarehouse(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLotWarehouseProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getWarehouseEcomProductlot(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }
  },
};
