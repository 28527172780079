import BaseRequest from "../BaseRequest"

export default class ProductRequest extends BaseRequest {
    getUnits(params) {
        return this.get(`go/partner_product/unit`, params)
    }
    getSpecifications(params) {
        return this.get(`go/partner_product/specification`, params)
    }
    getDosageForms(params) {
        return this.get(`go/partner_product/dosage_form`, params)
    }
    getManufacturers(params) {
        return this.get(`go/partner_product/manufacturer`, params)
    }
    getOrigins(params) {
        return this.getLocal(`go/partner_product/origin`, params)
    }
    getBrands(params) {
        return this.get(`go/partner_product/brand`, params)
    }
    getCategories(params) {
        return this.get(`go/partner_product/category`, params)
    }
    getProductDetailById(id) {
        return this.getLocal(`go/partner_product/products/supplier/${id}`)
    }
    createProduct(params) {
        return this.postLocal(`go/partner_product/products/create`, params)
    }
    updateProduct(id, params) {
        return this.putLocal(`go/partner_product/products/${id}`, params)
    }
    getWarehouses(params) {
        return this.getLocal(`go/partner_product/warehouse/list`, params)
    }
    createWarehouse(data) {
        return this.postLocal(`go/partner_product/warehouse`, data)
    }
    updateWarehouse(id, params, data) {
        return this.putLocal(`go/partner_product/warehouse/${id}?${new URLSearchParams(params).toString()}`, data)
    }
    deleteWarehouse(id) {
        return this.deleteLocal(`go/partner_product/warehouse/${id}`)
    }
}
