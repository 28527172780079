import auth from "@/middleware/auth"

export const formRoutes = [
  {
    path: "/print-consent-form/:id",
    name: "print-consent-form",
    component: () => import("@/views/PrintOrderForm/PrintConsentForm.vue"),
    meta: {
      middleware: [auth],
      layout: "full",
    },
  },
  {
    path: "/print-requisition-form/:id",
    name: "print-requisition-form",
    component: () => import("@/views/PrintOrderForm/index.vue"),
    meta: {
      middleware: [auth],
      layout: "full",
    },
  },
  {
    // Phiếu đồng thuận tầm soát nguy cơ ung thư di truyền
    path: "/print-consent-cancer-form/:id",
    name: "print-consent-cancer-form",
    component: () => import("@/views/PrintOrderForm/PrintConsentCancerForm.vue"),
    meta: {
      middleware: [auth],
      layout: "full",
    },
  },
  {
    // Phiếu xét nghiệm mẫu máu, mẫu ối tươi, gai nhau
    path: "/print-blood-sample-form/:id",
    name: "print-blood-sample-form",
    component: () => import("@/views/PrintOrderForm/PrintBloodSampleForm.vue"),
    meta: {
      middleware: [auth],
      layout: "full",
    },
  },
  {
    // Phiếu yêu cầu xét nghiệm
    path: "/print-require-form/:id",
    name: "print-require-form",
    component: () => import("@/views/PrintOrderForm/PrintRequireForm.vue"),
    meta: {
      middleware: [auth],
      layout: "full",
    },
  },
  {
    // Phiếu đồng thuận thực hiện xét nghiệm gen
    path: "/print-gene-test-form/:id",
    name: "print-gene-test-form",
    component: () => import("@/views/PrintOrderForm/PrintGeneTestForm.vue"),
    meta: {
      middleware: [auth],
      layout: "full",
    },
  },
  {
    path: "/experiment/:id",
    name: "print-experiment",
    component: () => import("@/views/PrintOrderForm/PrintExperimentForm.vue"),
    meta: {
      middleware: [auth],
      layout: 'full'
    },
  },
  {
    path: "/nipt/:id",
    name: "print-nipt",
    component: () => import("@/views/PrintOrderForm/TestTicket.vue"),
    meta: {
      middleware: [auth],
      layout: 'full'
    }
  },
  {
    path: "/g4500/:id",
    name: "print-g4500",
    component: () => import("@/views/PrintOrderForm/ExperimentTicket.vue"),
    meta: {
      middleware: [auth],
      layout: 'full'
    }
  },
  {
    path: "/ktrack/:id",
    name: "print-ktrack",
    component: () => import("@/views/PrintOrderForm/KtrackForm.vue"),
    meta: {
      middleware: [auth],
      layout: 'full'
    }
  }
]
