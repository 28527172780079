import * as enpointConfig from "../utils/endpoints";

import Vue from "vue";
import axios from "axios";
import store from "@/store";

// axios

const axiosIns = axios.create({
  baseURL: enpointConfig.BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosIns.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    if (
      error.response &&
      error.response.status &&
      [401, 403].includes(error.response.status)
    ) {
      // logout
      store.dispatch("authenticate/logout");
    }
    return Promise.reject(error);
  }
);
axiosIns.interceptors.request.use((config) => {
  if (store.state.authenticate.token) {
    config.headers = {
      Authorization: `Bearer ${store.state.authenticate.token}`,
    };
  }

  return config;
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
