import Vue from "vue";

export default {
  namespaced: true,
  state: {
    reportsList: [],
    reportsMeta: {},
  },
  getters: {},
  mutations: {
    setReportStore(state, data) {
      state.reportsList = data;
    },
    setReportMeta(state, data) {
      state.reportsMeta = data;
    },
  },
  actions: {
    getReportsList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getSaleReport(params)
          .then((result) => {
            const res = result.data;
            if (result?.status === 200) {
              ctx.commit("setReportStore", res?.data);
              ctx.commit("setReportMeta", res?.page);
            }
            resolve({
              success: true,
              result: res,
            });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    getProcessorReportsList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getReports(params)
          .then((result) => {
            const res = result.data;
            if (result?.status === 200) {
              ctx.commit("setReportStore", res?.data);
              ctx.commit("setReportMeta", res?.page);
            }
            resolve({
              success: true,
              result: res,
            });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
  },
};
