import axios from "@axios"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        // --------------vouchers--------------
        fetchCampaigns(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/campaigns", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        // --------------vouchers--------------
        fetchVouchers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/vouchers", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createVoucherGroup(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/comm/generate-voucher-code", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
    },
}
