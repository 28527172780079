import auth from "@/middleware/auth"
import checkLogin from "@/middleware/check-login"
import Vue from "vue"
import VueRouter from "vue-router"
import { eCommerceRoutes } from "./eCommerceRoutes"
import { formRoutes } from "./formRoutes"
import { testsRoutes } from "./testsRoutes"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        // pageTitle: "Home",
        middleware: [auth],
        can: 'common',
        // breadcrumb: [
        //   {
        //     text: "Home",
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        can: 'common',
        middleware: [checkLogin],
        layout: "full",
      },
    },
    {
      path: "/auto-login",
      name: "auto-login",
      component: () => import("@/views/AutoLogin/index.vue"),
      meta: {
        can: 'common',
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/Error404.vue"),
      meta: {
        can: 'common',
        layout: "full",
      },
    },
    // Campaigns
    {
      path: "/campaigns",
      name: "campaigns",
      component: () => import("@/views/Campaigns/index.vue"),
      meta: {
        can: 'campaigns',
        pageTitle: "lbl_campaigns",
        middleware: [auth],
      },
    },// Campaigns
    {
      path: "/campaigns/:id",
      name: "campaigns-id",
      component: () => import("@/views/Campaigns/_id/index.vue"),
      meta: {
        can: 'campaigns',
        pageTitle: "", // Start with an empty title
        middleware: [auth,],
        breadcrumb: [
          {
            text: "lbl_campaigns",
            active: false,
            to: "/campaigns",
          },
          {
            text: "lbl_add_new",
            active: true,
          },
        ],
      },
    },
    // Reports
    {
      path: "/reports",
      name: "reports",
      component: () => import("@/views/Reports/index.vue"),
      meta: {
        can: 'report',
        pageTitle: "lbl_reports",
        middleware: [auth],
        breadcrumb: [
          {
            text: "lbl_list",
            active: true,
          },
        ],
      },
    },
    // Result
    {
      path: "/processor/result",
      name: "processor_result",
      component: () => import("@/views/Result/processor/index.vue"),
      meta: {
        can: 'processor-service-result',
        pageTitle: "lbl_return_result",
        middleware: [auth],
        breadcrumb: [
          {
            text: "lbl_list",
            active: true,
          },
        ],
      },
    },
    {
      path: "/result/:id",
      name: "DetailReturnResult",
      component: () => import("@/views/Result/supplier/components/DetailReturnResult.vue"),
      meta: {
        can: 'service-result',
        pageTitle: "lbl_return_result",
        middleware: [auth],
        breadcrumb: [
          {
            text: "lbl_detail",
            active: true,
          },
        ],
      },
    },
    {
      path: "/supplier/result",
      name: "supplier_result",
      component: () => import("@/views/Result/supplier/index.vue"),
      meta: {
        can: 'service-result',
        pageTitle: "lbl_return_result",
        middleware: [auth],
        breadcrumb: [
          {
            text: "lbl_list",
            active: true,
          },
        ],
      },
    },
    // Analytics
    // {
    //   path: "/analytics",
    //   name: "analytics",
    //   component: () => import("@/views/Analytics/index.vue"),
    //   meta: {
    //     pageTitle: i18n.t("lbl_analytics"),
    //     middleware: [auth],
    //     breadcrumb: [
    //       {
    //         text: "lbl_list",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    ...testsRoutes,
    ...formRoutes,
    ...eCommerceRoutes,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
})
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next
  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({
      ...context,
      next: nextMiddleware,
    })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]
    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({
      ...context,
      next: nextMiddleware,
    })
  }
  return next()
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg")
  if (appLoading) {
    appLoading.style.display = "none"
  }
})

export default router
