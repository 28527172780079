import Vue from "vue";
import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /**
     * Categories
     */
    fetchCategoriesList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getCategories(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // fetchCategoryById(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/apps/categories/${id}`)
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
    // addCategory(ctx, data) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post("/apps/categories", { data })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    /**
     * Units
     */
    fetchUnitsList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getUnits(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    /**
     * Brands
     */
    fetchBrandsList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getBrands(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    /**
     * Delivery Status Configs
     */
    fetchDeliveryStatusConfigsList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDeliveryStatusConfigs(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDeliveryStatusConfigsListWithPaging(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDeliveryStatusConfigsListWithPaging(params)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    fetchDeliveryStatusConfigsById(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDeliveryStatusConfigsById(id, params)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    createDeliveryStatusConfigs(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .createNewDeliveryStatusConfig(data)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    updateDeliveryStatusConfigsById(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .updateDeliveryStatusConfigsById(id, data)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    deleteDeliveryStatusConfigsById(ctx, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .deleteDeliveryStatusConfigsById(id)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },

    /**
     * Definition Configs
     */
    fetchDeliveryDefinitionConfigsList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDeliveryDefinitionConfigs(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDeliveryDefinitionConfigsListWithPaging(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDeliveryDefinitionConfigsListWithPaging(params)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    fetchDeliveryDefinitionConfigsById(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDefinitionConfigsById(id, params)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    createDeliveryDefinitionConfigs(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .createNewDefinitionConfigs(data)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    updateDeliveryDefinitionConfigsById(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .updateDefinitionConfigsById(id, data)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    deleteDeliveryDefinitionConfigsById(ctx, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .deleteDefinitionConfigsById(id)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
  },
};
