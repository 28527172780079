<template>
  <div>
    <span class="title-form">{{ formName }}</span>
    <div class="mt-3">
      <div id="printOrderForm" class="mt-3">

        <!-- PATIENT INFORMATION -->
        <div>
          <span class="cs-title">THÔNG TIN NGƯỜI LÀM XÉT NGHIỆM</span>
          <div class="cs-flex">
            <div class="col-12 col-sm-4">
              <span class="cs-label">Họ tên</span>
              <b-form-input class="cs-content" v-model="form.full_name" />
            </div>
            <div class="col-12 col-sm-4">
              <span class="cs-label">Địa chỉ liên hệ</span>
              <b-form-input class="cs-content" v-model="form.address" />
            </div>
            <div class="col-12 col-sm-4">
              <span class="cs-label">Email</span>
              <b-form-input class="cs-content" v-model="form.email" />
            </div>
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-3">
              <span class="cs-label">Ngày sinh</span>
              <b-form-input type="date" placeholder="Chọn ngày sinh" v-model="form.birthday" />
            </div>
            <div class="col-12 col-sm-3">
              <span class="cs-label">Điện thoại</span>
              <b-form-input class="cs-content" v-model="form.phone_number" />
            </div>
            <div class="col-12 col-sm-3">
              <span class="cs-label">Tình trạng hôn nhân</span>
              <b-form-input class="cs-content" v-model="form.marriage_status" />
            </div>
            <div class="col-12 col-sm-3">
              <span class="cs-label">Giới tính</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.gender" name="some-radios0" :value="1"><label>Nam</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.gender" name="some-radios0" :value="2"><label>Nữ</label></b-form-radio>
              </div>
            </div>
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-6">
              <span class="cs-label">Bác sĩ chỉ định</span>
              <b-form-input class="cs-content" v-model="form.doctor_name" />
            </div>
            <div class="col-12 col-sm-6">
              <span class="cs-label">Phòng khám</span>
              <b-form-input class="cs-content" v-model="form.clinic" />
            </div>
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-6">
              <span class="cs-label">Ngày thu mẫu</span>
              <b-form-input class="cs-content" v-model="form.sample_taken_at" />
            </div>
            <div class="col-12 col-sm-6">
              <span class="cs-label">Nơi thu mẫu</span>
              <b-form-input class="cs-content" v-model="form.sample_taken_place" />
            </div>
          </div>
        </div>
        <!-- PATIENT INFORMATION -->            

        <!-- GENERAL INFORMATION -->
        <div>
          <span class="cs-title">THÔNG TIN CÁ NHÂN (cần thiết nhằm hỗ trợ phân tích kết quả)</span>
          <div class="cs-flex">
            <span class="cs-label col-12 cs-font-bold">A. TIỀN CĂN UNG THƯ/U LÀNH CỦA BẢN THÂN (thông tin bắt buộc)</span>
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-6">
              <span class="cs-label">Đã mắc/chẩn đoán ung thư:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.isHaveCancer" name="some-radios" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.isHaveCancer" name="some-radios" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>            
            <div class="col-12 col-sm-6">
              <span class="cs-label">Bệnh ung thư cụ thể:</span>
              <div class="d-flex cs-content">
               <b-form-input class="cs-content" v-model="form.cancer_type" />
              </div>
            </div>            
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-6">
              <span class="cs-label">Có u lành tính:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.isHaveBenignTumor" name="some-radios1" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.isHaveBenignTumor" name="some-radios1" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>            
            <div class="col-12 col-sm-6">
              <span class="cs-label">Cơ quan cụ thể:</span>
              <div class="d-flex cs-content">
               <b-form-input class="cs-content" v-model="form.benignTumorPosition" />
              </div>
            </div>
          </div>
          <div class="cs-flex">
            <span class="cs-label col-12" style="color: red;">
              Đối với u lành tính cần gởi kèm thông tin chi tiết khối u, thời
              gian phát hiện và điều trị (nếu có), kết quả cận lâm sàng (siêu
              âm, nhũ ảnh, X - Quang, nội soi, sinh thiết FNA,...). Cụ thể: u
              tuyến giáp (TIRAD 3,4) gởi kèm KQ siêu âm và/hoặc FNA hoặc u vú
              (BIRAD 3,4) gởi kèm KQ siêu âm/nhũ ảnh và/hoặc FNA
            </span>
          </div>
          <div class="cs-flex">
            <span class="cs-label col-12 cs-font-bold">B. THÓI QUEN - NGUY CƠ KHÁC</span>
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-6">
              <span class="cs-label">Hút thuốc lá:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.isSmoking" name="some-radios2" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.isSmoking" name="some-radios2" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>            
            <div class="col-12 col-sm-6">
              <span class="cs-label">Uống rượu:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.isDrinking" name="some-radios3" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.isDrinking" name="some-radios3" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-6">
              <span class="cs-label">Viêm gan siêu vi B, C:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.isHepatitis" name="some-radios4" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.isHepatitis" name="some-radios4" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>            
            <div class="col-12 col-sm-6">
              <span class="cs-label">Mang gen di truyền ung thư:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.is_carry_cancer_genes" name="some-radios5" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.is_carry_cancer_genes" name="some-radios5" :value="2"><label>Không</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.is_carry_cancer_genes" name="some-radios5" :value="3"><label>Chưa biết</label></b-form-radio>
              </div>
              <span class="cs-label">
                (Xét nghiệm ung thư di truyền được thực hiện 1 lần trong
                      đời có thể giúp biết được bản thân có mang gen di truyền
                      ung thư hay không)
              </span>
            </div>
          </div>
          <div class="cs-flex">
            <span class="cs-label col-12 cs-font-bold">C. CÁC DẤU HIỆU CỦA UNG THƯ</span>
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-6">
              <span class="cs-label">Sụt cân nhanh không rõ nguyên nhân (từ 6kg trở lên trong 6 tháng):</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.isLosingWeight" name="some-radios6" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.isLosingWeight" name="some-radios6" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <span class="cs-label">Ho kéo dài >3 tuần, uống thuốc không đỡ:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.isCough" name="some-radios7" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.isCough" name="some-radios7" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>            
          </div>
          <div class="cs-flex">
            <div class="col-12 col-sm-6">
              <span class="cs-label">Chảy máu, chảy dịch bất thường ở núm vú:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.is_fluid_discharge" name="some-radios8" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.is_fluid_discharge" name="some-radios8" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <span class="cs-label">Đau bụng hạ sườn phải và thượng vị kéo dài, uống thuốc không đỡ:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.is_stomach_ache" name="some-radios9" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.is_stomach_ache" name="some-radios9" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>            
          </div>
          <div class="cs-flex">
            <div class="col-12">
              <span class="cs-label">Rối loạn đi cầu thường xuyên: tiêu chảy - táo bón -
                đi cầu ra máu...:</span>
              <div class="d-flex cs-content">
                <b-form-radio class="mr-1" v-model="form.isConstipation" name="some-radios10" :value="1"><label>Có</label></b-form-radio>
                <b-form-radio class="mr-1" v-model="form.isConstipation" name="some-radios10" :value="2"><label>Không</label></b-form-radio>
              </div>
            </div>
          </div>
          <div class="cs-flex">
            <span class="cs-label col-12" style="color: red;">
              Nếu có một trong những dấu hiệu trên, anh/chị được khuyến cáo
                thực hiện các xét nghiệm phát hiện và chẩn đoán xác định ung
                thư.
            </span>
          </div>
        </div>
        <!-- GENERAL INFORMATION -->

        <!-- GENERAL INFORMATION -->
        <div>
          <span class="cs-title">TIỀN SỬ BỆNH UNG THƯ CỦA GIA ĐÌNH</span>
          <div class="cs-flex">
            <span class="cs-label col-12">
              Xin liệt kê các thành viên trong gia đình đã được chẩn đoán ung thư (mỗi hàng một thành viên)
            </span>
          </div>
          <div>
            <b-row class="mt-1 mb-1 mx-0">
              <b-col class="text-center" cols="5" sm="5">
                <strong>Mối quan hệ với bệnh nhân</strong>
              </b-col>
              <b-col class="text-center" cols="4" sm="5">
                <strong>Vị trí ung thư</strong>
              </b-col>
              <b-col class="text-center" cols="3" sm="2">
                <strong>Tuổi mắc</strong>
              </b-col>
            </b-row>
            <b-row v-for="(testInfo,index) in form.family_cancer" :key="index" class="mt-1 px-1">
              <b-col cols="5" sm="5" v-if="testInfo">
                <b-form-input class v-model="testInfo.relationship" />
              </b-col>
              <b-col class="text-center" v-if="testInfo" cols="4" sm="5">
                <b-form-input class v-model="testInfo.cancer_position" />
              </b-col>
              <b-col class="text-center" v-if="testInfo" cols="3" sm="2">
                <b-form-input class v-model="testInfo.ageHave" />
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- GENERAL INFORMATION -->

      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { mapState } from "vuex";

const DEFAULT_FORM = {
  full_name: "",
  birthday: "",
  address: "",
  phone_number: "",
  email: "",
  marriage_status: "",
  doctor_name: "",
  clinic: "",
  doctor_phone_number: null,
  sample_taken_at: "",
  sample_taken_place: "",
  isHaveCancer: null,
  cancer_type: "",
  isHaveBenignTumor: null,
  benignTumorPosition: "",
  isSmoking: null,
  isDrinking: null,
  isHepatitis: null,
  is_carry_cancer_genes: null,
  isLosingWeight: null,
  isCough: null,
  isConstipation: null,
  family_cancer: [
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
  ]
};

const optionsEditor = {
  placeholder: "",
  theme: "snow"
};

export default {
  name: "CreateBloodSampleForm",
  props: {
    productData: Object,
    createRequisitionFormCount: Number,
    formCount: Number,
    formData: Object,
    formName: String
  },
  components: { quillEditor },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      optionsEditor
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    createRequisitionFormCount() {
      this.handleCreateRequisitionForm();
    },
    formCount(count) {
      if (!count) return;
      this.handleGetFormData();
    },
    orders: {
      deep: true,
      handler(data) {
        if (data?.viewDetail) {
          this.handleMapData(data?.viewDetail);
        }
      }
    }
  },
  mounted() {
    if (this.orders?.viewDetail) {
      this.handleMapData(this.orders.viewDetail, this.formData);
    }
  },
  methods: {
    handleChangeSolidTumourType(isChecked, index) {
      if (isChecked) {
        this.form.solid_tumour_type.push(index);
      } else {
        this.form.solid_tumour_type = this.form.solid_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleChangeHaematologicalTumourType(isChecked, index) {
      if (isChecked) {
        this.form.haematological_tumour_type.push(index);
      } else {
        this.form.haematological_tumour_type = this.form.haematological_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleCreateRequisitionForm() {
      if (this.createRequisitionFormCount) {
        this.$emit("onCreate", this.form);
      } else {
        this.form = { ...DEFAULT_FORM };
      }
    },
    handleMapData(data, formData) {
      if (formData) {
        this.form = { ...this.form, ...formData };
      }
      const patientInfo =
        data?.order_contact?.find(item => item?.type === 2) ||
        data?.order_contact?.find(item => item?.type === 1);
      this.form.full_name = formData?.full_name || patientInfo?.name;
      this.form.address = formData?.address ||
        patientInfo?.address1 ||
        patientInfo?.address2;
      this.form.phone_number = formData?.phone_number || patientInfo?.phone;
      this.form.email = formData?.email || patientInfo?.email;
    },
    handleGetFormData() {
      this.$emit("onChangeFormData", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.title-form {
  font-size: 24px;
  color: black;
  text-align: center;
}
label {
  font-size: 14px;
}
.cs-container {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-label {
  width: 150px;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.cs-none {
  pointer-events: none;
}
.w-30 {
  width: 30%;
}
.flex-grow-2 {
  flex-grow: 2;
}
.cs-flex {
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}
@media (max-width: 576px) {
  .cs-flex {
    column-gap: 8px;
  }
}
</style>
<style>
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  @page {
      size: A4; /* DIN A4 standard, Europe */
      margin: 20mm 0 10mm 0;
      mso-header-margin: .5in; 
      mso-footer-margin: .5in; 
      mso-paper-source: 0;
    }
    html, body {
        /* width: 210mm; */
        /* height: 297mm; */
        /* height: 282mm; */
        background: #FFF;
        overflow: visible;
    }
    body {
        padding-top: 15mm;
    }
}
</style>