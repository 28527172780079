import Vue from "vue"

export default {
  namespaced: true,
  state: {
    productStore: [],
    productMeta: {},
  },
  getters: {},
  mutations: {
    setProductStore(state, data) {
      state.productStore = data
    },
    setProductMeta(state, data) {
      state.productMeta = data
    },
  },
  actions: {
    fetchProductsInventoryList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getProductInventory(params)
          .then((result) => {
            const res = result.data
            if (result?.status === 200) {
              ctx.commit("setProductStore", res?.data)
              ctx.commit("setProductMeta", res?.page)
            }
            resolve({
              success: true,
              result: res,
            })
          })
          .catch((error) => {
            reject(error.message)
          })
      })
    },
    fetchProductById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getProductById(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .addProduct(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateProduct(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .updateProduct(id, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateProductStatus(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .updateAvailablePartnerProduct(id, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchWarehouseList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("MarketplaceRequest")
          .getWarehouses(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    createWarehouse(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("MarketplaceRequest")
          .createWarehouse(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateWarehouse(ctx, { id, params, data }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("MarketplaceRequest")
          .updateWarehouse(id, params, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteWarehouse(ctx, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("MarketplaceRequest")
          .deleteWarehouse(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
