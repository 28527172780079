import BaseRequest from "../BaseRequest"

export default class CommonRequest extends BaseRequest {
  // Upload file to server
  uploadFileTenant(params) {
    return this.post(`go/core/files/requestUpload`, params)
  }
  // Categories
  getCategories(params) {
    return this.getLocal(`go/partner/products/categories`, params)
  }
  // Units
  getUnits(params) {
    return this.getLocal(`go/partner/products/units`, params)
  }
  // Variants
  deleteVariant(id) {
    return this.deleteLocal(`go/partner/products/variant/${id}`)
  }
  // Brands
  getBrands(params) {
    return this.getLocal(`go/partner/products/brands`, params)
  }
  // Delivery Status Configs
  getDeliveryStatusConfigs(params) {
    // Get all data and no paging
    return this.getLocal(`go/partner/product/delivery_configs`, params)
  }
  getDeliveryStatusConfigsListWithPaging(params) {
    // Get data with paging
    return this.getLocal(`go/partner/product/delivery_configs/list`, params)
  }
  getDeliveryStatusConfigsById(id, params) {
    return this.getLocal(`go/partner/product/delivery_configs/${id}`, params)
  }
  createNewDeliveryStatusConfig(data) {
    return this.postLocal(`go/partner/product/delivery_configs/create`, data)
  }
  updateDeliveryStatusConfigsById(id, data) {
    return this.putLocal(`go/partner/product/delivery_configs/${id}`, data)
  }
  deleteDeliveryStatusConfigsById(id) {
    return this.deleteLocal(`go/partner/product/delivery_configs/${id}`)
  }
  // Delivery Definition Configs
  getDeliveryDefinitionConfigs(params) {
    // Get all data and no paging
    return this.getLocal(`go/partner/product/attachment_configs`, params)
  }
  getDeliveryDefinitionConfigsListWithPaging(params) {
    // Get data with paging
    return this.getLocal(`go/partner/product/attachment_configs/list`, params)
  }
  getDefinitionConfigsById(id, params) {
    return this.getLocal(`go/partner/product/attachment_configs/${id}`, params)
  }
  createNewDefinitionConfigs(data) {
    return this.postLocal(`go/partner/product/attachment_configs/create`, data)
  }
  updateDefinitionConfigsById(id, data) {
    return this.putLocal(`go/partner/product/attachment_configs/${id}`, data)
  }
  deleteDefinitionConfigsById(id) {
    return this.deleteLocal(`go/partner/product/attachment_configs/${id}`)
  }
  // Products
  getProducts(params) {
    return this.getLocal(`go/partner/products`, params)
  }
  getProductById(id) {
    return this.getLocal(`go/partner/products/${id}`)
  }
  addProduct(data) {
    return this.postLocal(`go/partner/products/create`, data)
  }
  updateProduct(id, data) {
    return this.putLocal(`go/partner/products/update/${id}`, data)
  }
  deleteProduct(id) {
    return this.deleteLocal("go/partner/products/" + id)
  }
  // Product Certificates
  getProductCertificates(params) {
    return this.getLocal(`go/core/partner/product/cer/upload/list`, params)
  }
  updateProductCertificateItem(id, data) {
    return this.putLocal(`go/core/partner/product/cer/${id}`, data)
  }
  //Order
  getOrders(params) {
    return this.getLocal(`go/partner/products/supplierOrders`, params)
  }
  getDetailOrder(id, params) {
    return this.getLocal(`go/core/partner/supplierOrder/${id}`, params)
  }
  postUpdateStatusOrder(params) {
    return this.postLocal(`go/core/partner/supplierOrder/status`, params)
  }
  postUpdateAttachmentResult(params) {
    return this.postLocal(
      `go/core/partner/supplierOrder/attachmentResult`,
      params
    )
  }
  approveAttachmentResult(id) {
    return this.postLocal(
      `go/core/partner/supplierOrder/attachmentResult/approve/${id}`
    )
  }
  postOrderToAssignedProcessor(params) {
    return this.postLocal(`go/partner/processors/assign`, params)
  }

  //Product inventory
  getProductInventory(params) {
    return this.getLocal(`go/core/partner/inventory`, params)
  }
  addProductIntoInventory(params) {
    return this.postLocal(`go/core/partner/inventory`, params)
  }
  postUpdateProductStatus(id, params) {
    return this.postLocal(`go/core/partner/inventory/${id}`, params)
  }
  getOrderFormConfigs(params) {
    return this.getLocal(`go/partner/product/order_form_configs`, params)
  }

  //Reports
  getReports(params) {
    return this.getLocal(`go/core/report/processor`, params)
  }
  getReportsProductSale(params) {
    return this.getLocal(`go/core/report/productsale`, params)
  }
  getSaleReport(params) {
    return this.getLocal("go/core/report/supplierorders", params)
  }
  putOrderFormItem(id, params) {
    return this.putLocal(`go/orders/products/order_form_items/${id}`, params)
  }

  //Processors
  getProcessors(params) {
    return this.getLocal(`go/partner/processors`, params)
  }

  //Order processor
  getOrdersProcessor(params) {
    return this.getLocal(`go/partner/processors/orders`, params)
  }
  getOrdersProcessorV2(params) {
    return this.getLocal(`go/partner/processors/orders/gr_by_supplier`, params)
  }
  getDetailOrderProcessor(id) {
    return this.getLocal(`go/partner/processors/orders/${id}`)
  }
  postUpdateStatusOrderProcessor(params, id) {
    return this.putLocal(
      `go/partner/processors/orders/change_state/${id}`,
      params
    )
  }

  printDocument(params) {
    return this.post("doctor/emr-documents/printing-html", params)
  }

  updateOrderItemResultUrl(id, params) {
    return this.putLocal(`go/partner/products/order_items/${id}`, params)
  }
  putCancelOrder(id, params) {
    return this.putLocal(`go/partner/products/cancel/${id}`, params)
  }
  postDraftOrderForm(params) {
    return this.postLocal("go/partner/order_form/drafts", params)
  }
  getDraftOrderForm(params) {
    return this.getLocal("go/partner/order_form/drafts", params)
  }
  getNotifications(params) {
    return this.get("go/partner/products/ecom_notifies", params)
  }
  putUpdateReadNotifications(params) {
    return this.putLocal("go/partner/products/ecom_notifies/mark_read", params)
  }
  getConsentForm(params) {
    return this.getLocal("go/organization_consentform", params)
  }
  postComment(params) {
    return this.postLocal("go/ecom_comments/create", params)
  }
  getComments(params) {
    return this.getLocal("go/ecom_comments", params)
  }
  markNewCommentsAsRead(params) {
    return this.putLocal("go/ecom_comments/markread", params)
  }
  followOrders(params) {
    return this.postLocal("go/subscribes/follow", params)
  }
  unfollowOrders(params) {
    console.log(params)
    return this.putLocal("go/subscribes/unfollow", params)
  }
  paymentTransactionSlipOff(tx_code) {
    return this.postLocal(
      `go/service_transaction_slip/requestPaymentOfl/${tx_code}`
    )
  }

  // Return result
  processorReturnResult(params) {
    return this.postLocal(
      `go/core/partner/supplierOrder/attachment-result-multiples`, params
    )
  }
  completeOrder(id) {
    return this.postLocal(
      `go/service_delivery/${id}`
    )
  }
  supplierReturnResult(params) {
    return this.putLocal(
      `go/partner/products/send-result-customer`, params
    )
  }
  fetchListResult(params) {
    return this.getLocal("go/core/partner/supplierOrder/attachment-groups", params)
  }

  fetchListOrdersSupplier(params) {
    return this.getLocal("go/partner/products/supplier-order-results", params)
  }

  changeStatusOrderItem(params) {
    return this.putLocal("go/partner/processors/orders/change_multiple_state", params)
  }
  changeMultipleStatus(params) {
    return this.postLocal("go/core/partner/supplierOrder/change-multiple-status", params)
  }

  fetchListResultAttachment(params) {
    return this.getLocal("go/partner/processors/orders/result-list", params)
  }


  //Marketplace
  getWarehouseProduct(params) {
    return this.getLocal("go/partner_product/product_inventory", params)
  }
  getWarehouse(params) {
    return this.getLocal("go/partner_product/inventory/list", params)
  }
  getWarehouseProductLot(params) {
    return this.getLocal("go/partner_product/product_inventory/lot", params)
  }
  getWarehouseStockin(params) {
    return this.getLocal("go/partner_product/stock_in", params)
  }
  getWarehouseStockout(params) {
    return this.getLocal("go/partner_product/stock_out", params)
  }

  getProvider(params) {
    return this.getLocal("go/partner_product/provider", params)
  }

  getProductVariant(params) {
    return this.getLocal("go/partner_product/products/variant", params)
  }

  getProductLot(params) {
    return this.getLocal("go/partner_product/lot", params)
  }

  createStockIn(params) {
    return this.postLocal("go/partner_product/stock_in", params)
  }
  getDetailStockIn(id) {
    return this.getLocal(`go/partner_product/stock_in/${id}`)
  }

  getListEcomOrder(params) {
    return this.get("go/partner_product/orders", params)
  }
  getDetailEcomOrder(id) {
    return this.getLocal(`go/partner_product/orders/${id}`)
  }
  changePaymentStatusEcomOrder(params) {
    return this.putLocal("go/partner_product/orders", params)
  }
  changeStatusEcomOrder(params) {
    return this.putLocal("go/partner_product/orders/items", params)
  }
  cancelOrder(data) {
    return this.postLocal("go/partner_product/orders/supplier/cancel", data)
  }
  getWarehouseEcomProductlot(params) {
    return this.postLocal("go/partner_product/product_inventory/lot/inventory", params)
  }
  createStockout(params) {
    return this.postLocal("go/partner_product/stock_out", params)
  }
  getDetailStockOut(id) {
    return this.getLocal(`go/partner_product/stock_out/${id}`)
  }
  createEcomLot(params) {
    return this.postLocal("go/partner_product/lot/create", params)
  }
  updateAvailablePartnerProduct(id, params) {
    return this.putLocal(`go/partner_product/products/set_available/${id}`, params)
  }
  saveSupplierOrderNotes(params) {
    return this.postLocal(`go/core/partner/supplierOrder/notes`, params)
  }
  getSupplierOrderNotes(params) {
    return this.getLocal(`go/core/partner/supplierOrder/notes`, params)
  }
  getPatientOrg(params) {
    return this.getLocal(`go/user_members`, params)
  }
  getListPatient(params) {
    return this.getLocal(`go/user_members/users`, params)
  }
  configExchangeRate(params) {
    return this.putLocal(`go/org_exchange_rates`, params)
  }
  getExchangeRateDetail(id) {
    return this.getLocal(`go/org_exchange_rates/${id}`)
  }
  createPointProgram(params) {
    return this.postLocal(`go/point_program`, params)
  }
  getListPointProgram(params) {
    return this.getLocal(`go/point_program`, params)
  }
  depositPoint(params) {
    return this.postLocal(`go/point_program/top_up_point`, params)
  }
  getOrgFeature(id) {
    return this.get(`go/core/org/org-services/${id} `)
  }
  saveConfirmImage(params) {
    return this.postLocal(`go/service_delivery/attachment`, params)
  }
  getDetailDelivery(id) {
    return this.getLocal(`go/service_delivery/${id} `)
  }
  confirmDelivery(id) {
    return this.postLocal(`go/service_delivery/completed_order/${id}`)
  }
}
