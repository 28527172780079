import axios from "@axios"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        // --------------product--------------
        fetchProduct(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/products/supplier", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        // createProvider(ctx, data) {
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post("go/ecom/provider", data)
        //             .then((response) => resolve(response))
        //             .catch((error) => reject(error))
        //     })
        // },

        // updateProvider(ctx, { id, data }) {
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .put(`go/ecom/provider/${id}`, data)
        //             .then((response) => resolve(response))
        //             .catch((error) => reject(error))
        //     })
        // },

        checkPriority(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/category/priority", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        // --------------provider--------------
        fetchProvider(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/provider", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createProvider(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/ecom/provider", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        updateProvider(ctx, { id, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`go/ecom/provider/${id}`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },



        // --------------unit--------------
        fetchUnit(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/unit", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createUnit(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/ecom/unit", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        updateUnit(ctx, { id, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`go/ecom/unit/${id}`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },



        // --------------category--------------
        fetchCategory(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/category", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createCategory(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/ecom/category", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        updateCategory(ctx, { id, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`go/ecom/category/${id}`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },



        // --------------specification--------------
        fetchSpecification(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/specification", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createSpecification(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/ecom/specification", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        updateSpecification(ctx, { id, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`go/ecom/specification/${id}`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },



        // --------------origin--------------
        fetchOrigin(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/origin", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createOrigin(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/ecom/origin", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        updateOrigin(ctx, { id, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`go/ecom/origin/${id}`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },



        // --------------manufacturer--------------
        fetchManufacturer(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/manufacturer", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createManufacturer(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/ecom/manufacturer", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        updateManufacturer(ctx, { id, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`go/ecom/manufacturer/${id}`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },



        // --------------dosage_form--------------
        fetchDosageForm(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/dosage_form", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createDosageForm(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/ecom/dosage_form", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        updateDosageForm(ctx, { id, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`go/ecom/dosage_form/${id}`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },



        // --------------brand--------------
        fetchBrand(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("go/ecom/brand", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },
        createBrand(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("go/ecom/brand", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        },

        updateBrand(ctx, { id, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`go/ecom/brand/${id}`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            })
        }
    },
}
