import "./data/apps/eCommerce";
import "./data/apps/pharmaProduct";
import "./data/apps/orders";
import "./data/apps/products";
import "./data/apps/categories";

import mock from "./mock";

/* eslint-disable import/extensions */

/* eslint-enable import/extensions */

mock.onAny().passThrough(); // forwards the matched request over network
