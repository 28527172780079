import axios from "@axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    isLoadingDetail: false,
    viewDetail: {},
  },
  getters: {},
  mutations: {
    updateLoading(state, isLoading) {
      state.isLoadingDetail = isLoading;
    },
    updateViewDetail(state, data) {
      state.viewDetail = data;
    },
  },
  actions: {
    fetchOrdersProcessorList(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getOrdersProcessor(params)
          .then((result) => {
            const res = result.data;
            // if (result?.status === 200) {
            //   ctx.commit("setProductStore", res?.data);
            //   ctx.commit("setProductMeta", res?.page);
            // }
            resolve({
              success: true,
              result: res,
            });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    fetchOrdersProcessorListV2(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getOrdersProcessorV2(params)
          .then((result) => {
            const res = result.data;
            // if (result?.status === 200) {
            //   ctx.commit("setProductStore", res?.data);
            //   ctx.commit("setProductMeta", res?.page);
            // }
            resolve({
              success: true,
              result: res,
            });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    fetchOrdersProcessorById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getDetailOrderProcessor(id)
          .then((result) => {
            const res = result.data;
            // if (result?.status === 200) {
            //   ctx.commit("setProductStore", res?.data);
            //   ctx.commit("setProductMeta", res?.page);
            // }
            resolve({
              success: true,
              result: res,
            });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    addOrders(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/orders", { data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStatusOrderProcessor(ctx, { params, id }) {
      ctx.commit("updateLoading", true);
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .postUpdateStatusOrderProcessor(params, id)
          .then((result) => resolve(result))
          .catch((error) => {
            reject(error.message);
          })
          .finally(() => {
            ctx.commit("updateLoading", false);
          });
      });
    },
    updateAttachmentResult(ctx, { params, callback }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .postUpdateAttachmentResult(params)
          .then((result) => {
            resolve(result);
            callback();
          })
          .catch((error) => {
            reject(error.message);
          })
          .finally(() => {
            ctx.commit("updateLoading", false);
          });
      });
    },
  },
};
