<template>
  <div>
    <span class="title-form">{{ formName.toUpperCase() }}</span>
    <div class="mt-3">
      <div id="printOrderForm" class="mt-3">
        <!-- PAGE 1 -->
        <div>
          <!-- COLUgendertseMN LEFT -->
          <div>
            <!-- PATIENT INFORMATION -->
            <div>
              <span class="cs-title">PATIENT INFORMATION/ <i>THÔNG TIN BỆNH NHÂN</i></span>
              <div class="cs-flex">
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Full name/ <i>Họ tên</i></span>
                  <b-form-input class="cs-content" v-model="form.full_name" />
                </div>
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Birthday/ <i>Ngày sinh</i></span>
                  <b-form-input type="date" placeholder="Chọn ngày sinh nhật" v-model="form.birthday" />
                </div>
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Gender/ <i>Giới</i></span>
                  <div class="d-flex cs-content">
                    <div class="mr-1 cs-checkbox" :set="isMale=form.gender===1">
                      <input
                        v-model="isMale"
                        id="male"
                        name="male"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.gender = 1 : form.gender = null"
                      />
                      <label>Male/ <i>Nam</i></label>
                    </div>
                    <div class="mr-1 cs-checkbox" :set="isFemale=form.gender===2">
                      <input
                        v-model="isFemale"
                        id="female"
                        name="female"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.gender = 2 : form.gender = null"
                      />
                      <label>Female/ <i>Nữ</i></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cs-flex">
                <div class="col-12 col-sm-7">
                  <span class="cs-label">Address/ <i>Địa chỉ</i></span>
                  <b-form-input class="cs-content" v-model="form.address" />
                </div>
                <div class="col-12 col-sm-5">
                  <span class="cs-label">Phone Number/ <i>SĐT</i></span>
                  <b-form-input class="cs-content" v-model="form.phone_number" />
                </div>
              </div>
              <div class="cs-flex">
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Doctor/ <i>Bác sĩ</i></span>
                  <b-form-input class="cs-content" v-model="form.doctor_name" />
                </div>
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Clinic/Hospital/ <i>PK/Bệnh viện</i></span>
                  <b-form-input class="cs-content" v-model="form.clinic" />
                </div>
                <div class="col-12 col-sm-4">
                  <span class="cs-label">Phone Number/ <i>SĐT</i></span>
                  <b-form-input class="cs-content" v-model="form.doctor_phone_number" />
                </div>
              </div>
              <div class="cs-flex">
                <div class="col-12 col-sm-7">
                  <span class="cs-label">Sample Collection Date & Time/ <i>Ngày thu mẫu</i></span>
                  <b-form-input type="date" placeholder="Chọn ngày lấy mẫu" v-model="form.sample_taken_at" />
                </div>
                <div class="col-12 col-sm-5">
                  <span class="cs-label">Sample ID/ <i>Mã XN</i></span>
                  <b-form-input class="cs-content" v-model="form.sample_id" />
                </div>
              </div>
            </div>
            <!-- PATIENT INFORMATION -->
            <!-- TEST REQUIREMENT -->
            <div>
              <span class="cs-title">TEST REQUIREMENT/ <i>XÉT NGHIỆM YÊU CẦU</i></span>
              <div class="col-12">
                <div class="d-flex flex-column cs-content" style="gap: 10px;">
                  <b-form-radio class="mr-1" v-model="form.test_requirement" name="some-radios" :value="1"><label><b>Ung thư vú:</b> BRCA1, BRCA2, PALB2, PTEN, TP53, CDH1, STK11</label></b-form-radio>
                  <b-form-radio class="mr-1" v-model="form.test_requirement" name="some-radios" :value="2"><label><b>17 gen liên quan đến hơn 11 loại ung thư di truyền:</b> BRCA1, BRCA2, PALB2, PTEN, TP53, CDH1, MLH1, MSH2, MSH6, PMS2, EPCAM, APC, MUTYH, STK11, VHL, RB1, RET</label></b-form-radio>
                  <b-form-radio v-model="form.test_requirement" name="some-radios" :value="3">
                    <label><b>133 gen liên quan đến hơn 20 loại ung thư di truyền:</b> AIP, ALK, ANKRD26, APC, ATM, AXIN2, BAP1, BARD1, BLM, BMPR1A*, BRAF*, BRCA1*, BRCA2, BRIP1, BUB1B, CBL, CDC73, CDH1, CDK4, CDKN1B, CDKN1C, CDKN2A, CEBPA, CEP57, CHEK2*, CYLD, DDB2, DICER1*, DIS3L2*, DKC1, EGFR, ELANE, EPCAM, ERCC1, ERCC2, ERCC3, ERCC4, ERCC5, ETV6, EXO1, EXT1, EXT2, EZH2, FANCA, FANCB, FANCC, FANCD2*, FANCE, FANCF, FANCG, FANCI, FANCL, FANCM, FH, FLCN, GATA2, GCP3, GREMI, HNF1A, HOXB13, HRAS, KIT, KITLG, KRAS*, MAP2K1, MAP2K2, MAX, MEN1, MET, MITF, MLH1, MLH3, MRE11A, MSH2, MSH6, MUTYH, NBN, NF1*, NF2, NRAS, NSD1, NSUN2, NTHL1, PALB2, PAX5, PDGFRA, PHOX2B, PMS1, PMS2*, POLD1, POLH*, PPM1D, PRF1, PRKAR1A, PTCH1, PTEN*, PTPN11, RAD50, RAD51C, RAF1, RECQL4, RET, RHBDF2, RUNX1, SDHA*, SDHAF2, SDHB, SDHC, SDHD, SHOC2, SLX4, SMAD4, SMARCA4, SMARCB1, SOS1, SPRED1, SRP72*, STK11, SUFU, TERC, TERT, TINF2, TMEM127, TP53, TSC1, TSC2, VHL, WRN*, WT1, XPA, XPC, XRCC2</label>
                  </b-form-radio>
                </div>
              </div>
            </div>
            <!-- TEST REQUIREMENT -->
            <!-- CANCER HISTORY -->
            <div>
              <span class="cs-title">CANCER HISTORY/ <i>TIỀN SỬ UNG THƯ CỦA BỆNH NHÂN</i></span>
              <div class="cs-flex">
                <div class="d-flex flex-column cs-content">
                  <div class="mb-1 cs-checkbox">
                    Nếu bệnh nhân bị ung thư, xin cung cấp thông tin:
                  </div>
                  <div class="mb-1">
                    <div class="cs-checkbox w-30">
                      <input v-model="form.cancer_history.breastCancer.isHave" id="urgent" name="urgent" type="checkbox" />
                      <label><b>Ung thư vú.</b></label>
                    </div>
                    <div class="d-flex">
                      <div class="cs-flex flex-grow-1">
                        <span class="cs-label">Tuổi mắc:
                        </span>
                        <b-form-input class="cs-content" v-model="form.cancer_history.breastCancer.ageHave" />
                      </div>
                      <div class="cs-flex flex-grow-2">
                        <span class="cs-label">Phân loại (nếu biết):
                        </span>
                        <b-form-input class="cs-content" v-model="form.cancer_history.breastCancer.category" />
                      </div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="cs-checkbox w-30">
                      <input v-model="form.cancer_history.ovaryCancer.isHave" id="urgent" name="urgent" type="checkbox" />
                      <label><b>Ung thư buồng trứng.</b></label>
                    </div>
                    <div class="cs-flex flex-grow-1">
                      <span class="cs-label">Tuổi mắc:
                      </span>
                      <b-form-input class="cs-content" v-model="form.cancer_history.ovaryCancer.ageHave" />
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="cs-checkbox w-30">
                      <input v-model="form.cancer_history.intestineCancer.isHave" id="urgent" name="urgent" type="checkbox" />
                      <label><b>Ung thư ruột/ trực tràng.</b></label>
                    </div>
                    <div class="d-flex">
                      <div class="cs-flex flex-grow-1">
                        <span class="cs-label">Tuổi mắc:
                        </span>
                        <b-form-input class="cs-content" v-model="form.cancer_history.intestineCancer.ageHave" />
                      </div>
                      <div class="cs-flex flex-grow-2">
                        <span class="cs-label">Phân loại (nếu biết):
                        </span>
                        <b-form-input class="cs-content" v-model="form.cancer_history.intestineCancer.category" />
                      </div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="cs-checkbox w-30">
                      <input v-model="form.cancer_history.uterusCancer.isHave" id="urgent" name="urgent" type="checkbox" />
                      <label><b>Ung thư nội mạc tử cung.</b></label>
                    </div>
                    <div class="cs-flex flex-grow-1">
                      <span class="cs-label">Tuổi mắc:
                      </span>
                      <b-form-input class="cs-content" v-model="form.cancer_history.uterusCancer.ageHave" />
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="cs-checkbox w-30">
                      <input v-model="form.cancer_history.polyp.isHave" id="urgent" name="urgent" type="checkbox" />
                      <label><b>Polyp ở ruột/ trực tràng</b></label>
                    </div>
                    <div class="cs-flex flex-grow-1">
                      <span class="cs-label">Số lượng polyp:
                      </span>
                      <b-form-input class="cs-content" v-model="form.cancer_history.polyp.number" />
                    </div>
                  </div>
                  
                  <div class="mb-1 d-block">
                    <span class="cs-label"><b><u>Kết quả IHC của khối u bất thường:</u></b>
                    </span>
                    <b-form-input class="cs-content mt-1" v-model="form.cancer_history.ihc_result" />
                  </div>
                </div>
              </div>
            </div>
            <!-- CANCER HISTORY -->
            <!-- FAMILY MEMBER'S CANCER HISTORY -->
            <div class="mb-2">
              <span class="cs-title">FAMILY MEMBER'S CANCER HISTORY/ <i>TIỀN SỬ BỆNH UNG THƯ CỦA GIA ĐÌNH</i></span>
              <div class="cs-flex">
                <span>Xin liệt kê các thành viên trong gia đình đã được chẩn đoán ung thư (mỗi hàng một thành viên)</span>
              </div>
              <div>
                <b-row class="mt-1 mb-1 mx-0">
                  <b-col class="text-center" cols="5" sm="5">
                    <strong>Mối quan hệ với bệnh nhân</strong>
                  </b-col>
                  <b-col class="text-center" cols="4" sm="5">
                    <strong>Vị trí ung thư</strong>
                  </b-col>
                  <b-col class="text-center" cols="3" sm="2">
                    <strong>Tuổi mắc</strong>
                  </b-col>
                </b-row>
                <b-row v-for="(testInfo,index) in form.family_cancer" :key="index" class="mt-1 px-1">
                  <b-col cols="5" sm="5" v-if="testInfo">
                    <b-form-input class v-model="testInfo.relationship" />
                  </b-col>
                  <b-col cols="4" sm="5" class="text-center" v-if="testInfo">
                    <b-form-input class v-model="testInfo.cancer_position" />
                  </b-col>
                  <b-col cols="3" sm="2" class="text-center" v-if="testInfo">
                    <b-form-input class v-model="testInfo.ageHave" />
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- FAMILY MEMBER'S CANCER HISTORY -->
            
            <div class="mb-2">
              <span class="cs-title">PATIENT'S CONSENT CONFIRMATION/ <i>ĐỒNG Ý LÀM XÉT NGHIỆM CỦA BỆNH NHÂN</i></span>
              <div class="cs-flex">
                <span>Tôi tự nguyện đồng ý làm xét nghiệm tầm soát đột biến của các gen:</span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.gene_list" />
              </div>
              <div class="cs-flex">
                <span>
                  Xét nghiệm di truyền đối với trẻ em dưới 18 tuổi phải có sự đồng ý của cha mẹ hoặc người giám hộ hợp pháp. Nếu là người giám hộ hợp pháp, vui lòng ghi rõ mối quan hệ với bệnh nhân:
                </span>
              </div>
              <div class="cs-flex">
                <b-form-input class="cs-content" v-model="form.relationship_to_patient" />
              </div>
            </div>
          </div>          
        </div>
        <!-- PAGE 1 -->

      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { mapState } from "vuex";

const DEFAULT_FORM = {
  full_name: "",
  birthday: "",
  gender: null,
  address: "",
  phone_number: "",
  doctor_name: "",
  clinic: "",
  doctor_phone_number: null,
  sample_taken_at: "",
  sample_id: "",
  test_requirement: null,
  cancer_history: {
    breastCancer: {
      isHave: false,
      ageHave: null,
      category: ""
    },
    ovaryCancer: {
      isHave: false,
      ageHave: null,
    },
    intestineCancer: {
      isHave: false,
      ageHave: null,
      category: ""
    },
    uterusCancer: {
      isHave: false,
      ageHave: null,
    },
    polyp: {
      isHave: false,
      number: null,
    },
    ihc_result: ""
  },
  family_cancer: [
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
  ],
  gene_list: "",
  relationship_to_patient: ""
};

const optionsEditor = {
  placeholder: "",
  theme: "snow"
};

export default {
  props: {
    productData: Object,
    createRequisitionFormCount: Number,
    formCount: Number,
    formData: Object,
    formName: String
  },
  components: { quillEditor },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      optionsEditor
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    createRequisitionFormCount() {
      this.handleCreateRequisitionForm();
    },
    formCount(count) {
      if (!count) return;
      this.handleGetFormData();
    },
    orders: {
      deep: true,
      handler(data) {
        if (data?.viewDetail) {
          this.handleMapData(data?.viewDetail);
        }
      }
    }
  },
  mounted() {
    if (this.orders?.viewDetail) {
      this.handleMapData(this.orders.viewDetail, this.formData);
    }
  },
  methods: {
    handleChangeSolidTumourType(isChecked, index) {
      if (isChecked) {
        this.form.solid_tumour_type.push(index);
      } else {
        this.form.solid_tumour_type = this.form.solid_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleChangeHaematologicalTumourType(isChecked, index) {
      if (isChecked) {
        this.form.haematological_tumour_type.push(index);
      } else {
        this.form.haematological_tumour_type = this.form.haematological_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleCreateRequisitionForm() {
      if (this.createRequisitionFormCount) {
        this.$emit("onCreate", this.form);
      } else {
        this.form = { ...DEFAULT_FORM };
      }
    },
    handleMapData(data, formData) {
      if (formData) {
        this.form = { ...this.form, ...formData };
      }
      const patientInfo =
        data?.order_contact?.find(item => item?.type === 2) ||
        data?.order_contact?.find(item => item?.type === 1);
      this.form.full_name = formData?.full_name || patientInfo?.name;
      this.form.address = formData?.address ||
        patientInfo?.address1 ||
        patientInfo?.address2;
      this.form.phone_number = formData?.phone_number || patientInfo?.phone;
      this.form.email = formData?.email || patientInfo?.email;
    },
    handleGetFormData() {
      this.$emit("onChangeFormData", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.title-form {
  font-size: 24px;
  color: black;
  text-align: center;
}
label {
  font-size: 14px;
}
.cs-container {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  padding: 6px;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
}

.cs-label {
  width: 150px;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.cs-none {
  pointer-events: none;
}
.w-30 {
  width: 30%;
}
.flex-grow-2 {
  flex-grow: 2;
}

@media (max-width: 576px) {
  .w-30 {
    width: 100%;
  }
  .d-flex {
    flex-wrap: wrap;
  }
  .cs-label {
    width: 100px;
  }
}
</style>
<style>
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
</style>